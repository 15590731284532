import React from "react";

function MobilePost() {
  return (
    <div>
      <h2>Postpaid Mobile Bill</h2>
    </div>
  );
}

export default MobilePost;
