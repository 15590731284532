import React from "react";
import EmptyWishList from "./EmptyWishList";
import WishListData from "./WishListData";
import { useWishlist } from "../../store/WishlistContext";

function WhishList() {
  const { wishlist } = useWishlist();
  return (
    <div>
      {wishlist.length === 0 && <EmptyWishList />}
      {wishlist.length !== 0 && <WishListData list={wishlist} />}
    </div>
  );
}

export default WhishList;
