import EmptyCart from "../../components/cart/EmptyCart";
import Cart from "../../components/cart/Cart";

import { useCartList } from "../../store/CartContext";

function MyCart() {
  const { cart } = useCartList();
  return (
    <>
      <div>
        {cart.length === 0 && <EmptyCart />}
        {cart.length !== 0 && <Cart />}
      </div>
    </>
  );
}

export default MyCart;
