import React from "react";

function Mobile() {
  return (
    <div>
      <h2>Mobile</h2>
    </div>
  );
}

export default Mobile;
