import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";
import axios from "axios";

const API_URL = "https://mason-api1.azurewebsites.net/api"; // replace with your backend URL

const AddressContext = createContext();

export const useAddressList = () => useContext(AddressContext);

export const AddressContextProvider = ({ children }) => {
  const { token, userId } = useAuthContext();

  const [address, setAddress] = useState([]);
  const getAddresslist = async () => {
    try {
      const response = await axios.get(`${API_URL}/address/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const addressItems = response.data;
      setAddress(addressItems);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    if (userId) {
      getAddresslist();
    }
  }, [userId, token]);
  //console.log(address);

  const addAddress = async (credentials) => {
    //console.log(credentials);

    try {
      let {
        addressId,
        firstNameValue,
        middleNameValue,
        lastNameValue,
        mobileValue,
        emailValue,
        flatValue,
        cityValue,
        stateValue,
        pinCodeValue,
        countryValue,
        checkedBox,
      } = credentials;

      const addressPresent = address.find(
        (item) => item.address_id === addressId
      );
      const method = addressPresent ? "put" : "post";
      const url = `${API_URL}/address`; // Include product_id in URL if updating

      const response = await axios({
        method: method,
        url: url,
        data: {
          address_id: addressId,
          user_id: userId,
          first_name: firstNameValue,
          middle_name: middleNameValue,
          last_name: lastNameValue,
          mobile: mobileValue,
          email: emailValue,
          address: flatValue,
          city: cityValue,
          state: stateValue,
          pincode: pinCodeValue,
          country: countryValue,
          default_address: checkedBox,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedAddress = await response.data;

      if (addressPresent) {
        setAddress(
          address.map((x) =>
            x.address_id === addressId ? { ...addressPresent } : x
          )
        );
      } else {
        setAddress([...address, { ...updatedAddress }]);
      }
      getAddresslist();
    } catch (error) {
      console.error("Error adding item to address:", error);
    }
  };

  const deleteAddress = async (addressId) => {
    try {
      const response = await axios.delete(`${API_URL}/address/${addressId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is set
        },
      });

      setAddress(address.filter((x) => x.address_id !== addressId));
    } catch (error) {
      console.error("Error deleting item from address:", error);
    }
  };

  return (
    <AddressContext.Provider
      value={{ address, addAddress, deleteAddress, getAddresslist }}
    >
      {children}
    </AddressContext.Provider>
  );
};
