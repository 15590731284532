import AddtoCart from "../../components/cart/AddtoCart";
import { useLocation } from "react-router-dom";

const AddtoCartScreen = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <div>
      <AddtoCart item={data} />
    </div>
  );
};

export default AddtoCartScreen;
