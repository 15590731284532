import { useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import CardBackground from "../../components/ui/CardBackground";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { useAddressList } from "../../store/AddressContext";
import { useLocation } from "react-router-dom";
import CustomTitle from "../../components/ui/CustomTitle";
const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");

const NewAddress = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addAddress } = useAddressList();
  const [checkedBox, setCheckedBox] = useState(false);
  const initialValues = location.state?.data || {};
  useEffect(() => {
    if (Object.keys(initialValues).length > 0) {
      setFirstNameValue(initialValues.first_name || "");
      setMiddleNameValue(initialValues.middle_name || "");
      setLastNameValue(initialValues.last_name || "");
      setMobileValue(initialValues.mobile || "");
      setEmailValue(initialValues.email || "");
      setFlatValue(initialValues.address || "");
      setPinCodeValue(initialValues.pincode || "");
      setCityValue(initialValues.city || "");
      setStateValue(initialValues.state || "");
      setCountryValue(initialValues.country || "");
      setCheckedBox(initialValues.default_address || false);
    }
  }, [initialValues]);

  const {
    value: firstNameValue,
    setvalue: setFirstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(isNotEmpty);
  const {
    value: middleNameValue,
    setvalue: setMiddleNameValue,
    isValid: middleNameIsValid,
    hasError: middleNameHasError,
    valueChangeHandler: middleNameChangeHandler,
    reset: resetMiddleName,
  } = useInput(isNotEmpty);
  const {
    value: lastNameValue,
    setvalue: setLastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput(isNotEmpty);
  const {
    value: mobileValue,
    setvalue: setMobileValue,
    isValid: mobileIsValid,
    hasError: mobileHasError,
    valueChangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    reset: resetMobile,
  } = useInput(isNotEmpty);
  const {
    value: emailValue,
    setvalue: setEmailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);

  const {
    value: flatValue,
    setvalue: setFlatValue,
    isValid: flatIsValid,
    hasError: flatHasError,
    valueChangeHandler: flatChangeHandler,
    inputBlurHandler: flatBlurHandler,
    reset: resetFlat,
  } = useInput(isNotEmpty);
  const {
    value: pinCodeValue,
    setvalue: setPinCodeValue,
    isValid: pinCodeIsValid,
    hasError: pinCodeHasError,
    valueChangeHandler: pinCodeChangeHandler,
    inputBlurHandler: pinCodeBlurHandler,
    reset: resetPinCode,
  } = useInput(isNotEmpty);
  const {
    value: cityValue,
    setvalue: setCityValue,
    isValid: cityIsValid,
    hasError: cityHasError,
    valueChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
    reset: resetCity,
  } = useInput(isNotEmpty);
  const {
    value: stateValue,
    setvalue: setStateValue,
    isValid: stateIsValid,
    hasError: stateHasError,
    valueChangeHandler: stateChangeHandler,
    inputBlurHandler: stateBlurHandler,
    reset: resetState,
  } = useInput(isNotEmpty);
  const {
    value: countryValue,
    setvalue: setCountryValue,
    isValid: countryIsValid,
    hasError: countryHasError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
    reset: resetCountry,
  } = useInput(isNotEmpty);
  const handleCheckboxChange = (event) => {
    setCheckedBox(event.target.checked);
  };
  let formIsValid = false;

  if (
    firstNameIsValid &&
    lastNameIsValid &&
    mobileIsValid &&
    emailIsValid &&
    flatIsValid &&
    pinCodeIsValid &&
    cityIsValid &&
    stateIsValid &&
    countryIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    } else {
      const credentials = {
        firstNameValue,
        middleNameValue,
        lastNameValue,
        mobileValue,
        emailValue,
        flatValue,
        cityValue,
        pinCodeValue,
        stateValue,
        countryValue,
        checkedBox,
      };
      try {
        await addAddress(credentials); // Wait for the operation to complete
        navigate("/profile/AddressBook"); // Navigate after the update
      } catch (error) {
        console.error("Error updating address:", error);
      }
    }
  };
  const UpdateHandler = async () => {
    const addressId = initialValues?.address_id; // Adjust as necessary
    const credentials = {
      addressId,
      firstNameValue,
      middleNameValue,
      lastNameValue,
      mobileValue,
      emailValue,
      flatValue,
      cityValue,
      pinCodeValue,
      stateValue,
      countryValue,
      checkedBox,
    };

    try {
      await addAddress(credentials); // Wait for the operation to complete
      navigate("/profile/AddressBook");
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };
  const firstNameClasses = firstNameHasError
    ? "form-control invalid"
    : "form-control";
  const lastNameClasses = lastNameHasError
    ? "form-control invalid"
    : "form-control";
  const mobileClasses = mobileHasError
    ? "form-control invalid"
    : "form-control";
  const emailClasses = emailHasError ? "form-control invalid" : "form-control";
  const flatClasses = flatHasError ? "form-control invalid" : "form-control";
  const pinCodeClasses = pinCodeHasError
    ? "form-control invalid"
    : "form-control";
  const cityClasses = cityHasError ? "form-control invalid" : "form-control";
  const stateClasses = stateHasError ? "form-control invalid" : "form-control";
  const countryClasses = countryHasError
    ? "form-control invalid"
    : "form-control";

  return (
    <div className="luxury" style={{ maxWidth: "800px" }}>
      <div className="heading">
        {Object.keys(initialValues).length > 0 ? (
          <CustomTitle>Update Your Address</CustomTitle>
        ) : (
          <CustomTitle>Add a New Address</CustomTitle>
        )}
      </div>
      <CardBackground>
        <form>
          <div className="control-group">
            <div className={firstNameClasses}>
              <input
                type="text"
                placeholder="First Name."
                value={firstNameValue}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
              />
              {firstNameHasError && (
                <p className="error-text">Please enter a First Name.</p>
              )}
            </div>
            <div className="form-control">
              <input
                type="text"
                placeholder="Middle Name."
                value={middleNameValue}
                onChange={middleNameChangeHandler}
              />
            </div>
            <div className={lastNameClasses}>
              <input
                type="text"
                placeholder="Last Name."
                value={lastNameValue}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
              />
              {lastNameHasError && (
                <p className="error-text">Please enter a Last Name.</p>
              )}
            </div>

            <div className={mobileClasses}>
              <input
                type="number"
                maxLength="10"
                placeholder="Mobile Number."
                value={mobileValue}
                onChange={mobileChangeHandler}
                onBlur={mobileBlurHandler}
              />
              {mobileHasError && (
                <p className="error-text">Please enter a Mobile Number.</p>
              )}
            </div>
            <div className={emailClasses}>
              <input
                type="text"
                placeholder="Email."
                value={emailValue}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
              />
              {emailHasError && (
                <p className="error-text">Please enter a Email.</p>
              )}
            </div>

            <div className={flatClasses}>
              <input
                type="text"
                placeholder="Flat,Company,Apartment"
                value={flatValue}
                onChange={flatChangeHandler}
                onBlur={flatBlurHandler}
              />
              {flatHasError && (
                <p className="error-text">Please enter Your Address.</p>
              )}
            </div>

            <div className={pinCodeClasses}>
              <input
                type="number"
                maxLength="6"
                placeholder="Pin Code"
                value={pinCodeValue}
                onChange={pinCodeChangeHandler}
                onBlur={pinCodeBlurHandler}
              />
              {pinCodeHasError && (
                <p className="error-text">Please enter Your Pin Code.</p>
              )}
            </div>

            <div className={cityClasses}>
              <input
                type="text"
                placeholder="City"
                value={cityValue}
                onChange={cityChangeHandler}
                onBlur={cityBlurHandler}
              />
              {cityHasError && (
                <p className="error-text">Please enter Your City.</p>
              )}
            </div>

            <div className={stateClasses}>
              <input
                type="text"
                placeholder="State"
                value={stateValue}
                onChange={stateChangeHandler}
                onBlur={stateBlurHandler}
              />
              {stateHasError && (
                <p className="error-text">Please enter Your State.</p>
              )}
            </div>
            <div className={countryClasses}>
              <input
                type="text"
                placeholder="Country"
                value={countryValue}
                onChange={countryChangeHandler}
                onBlur={countryBlurHandler}
              />
              {countryHasError && (
                <p className="error-text">Please enter Your Country.</p>
              )}
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={checkedBox}
                  onChange={handleCheckboxChange}
                />
                Default Address
              </label>
            </div>
            <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
              {Object.keys(initialValues).length > 0 ? (
                <Button type="submit" onClick={UpdateHandler}>
                  Update Address
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={!formIsValid}
                  onClick={submitHandler}
                >
                  Add Address
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardBackground>
    </div>
  );
};

export default NewAddress;
