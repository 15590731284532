import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import ProductsPage from "../../../pages/home/ProductsPage";
import Loader from "react-js-loader";

const BrandsOverView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const brandId = params.id;
  const [brandsList, setBrandsList] = useState("true");
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          brand_id: tasksObj[taskKey].brand_id,
          categoryId: tasksObj[taskKey].category_2_id,
          product_id: tasksObj[taskKey].product_id,
          product_name: tasksObj[taskKey].product_name,
          product_brand: tasksObj[taskKey].product_brand,
          product_type: tasksObj[taskKey].product_type,
          product_quality: tasksObj[taskKey].product_quality,
          price_unit: tasksObj[taskKey].price_unit,
          product_price: tasksObj[taskKey].product_price,
          product_img_uri: tasksObj[taskKey].product_img_uri,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/brands/${brandId}`,
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <div>
      <ProductsPage dataList={tasks} BrandsList={brandsList} />
    </div>
  );
};

export default BrandsOverView;
