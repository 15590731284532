import { React, useState } from "react";
import styles from "./Navbar.module.css";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import DeliverySlot from "./DeliverySlot";
import { useAuthContext } from "../../../store/AuthContext";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const { isAuthenticated } = useAuthContext();
  return (
    <header className={styles.navbar}>
      <nav>
        <ul
          className={
            nav ? [styles.menu, styles.active].join(" ") : [styles.menu]
          }
        >
          {/*     <li><NavLink to={"/"} exact ClassName={({ isActive}) => isActive ? styles.active : undefined}>Home</NavLink></li>
          <li><NavLink to={"/category/:id"}  exact activeClassName={styles.active}>Shop</NavLink></li>  */}
          <li>
            <NavLink to={"/"}>Home</NavLink>
          </li>
          <li>
            <NavLink to={"/category/:id"}>Shop</NavLink>
          </li>
          <li>
            <NavLink to={"/brands"}>Brands</NavLink>
          </li>
          <li>
            {/* <NavLink to={"/deals"}>Deals</NavLink> */}
            <NavLink to={"/"}>Deals</NavLink>
          </li>
          <li>
            <NavLink to={"/services"}>Services</NavLink>
          </li>
          {isAuthenticated && (
            <li>
              <NavLink to={"/profile"}>Profile</NavLink>
            </li>
          )}
        </ul>
      </nav>
      <div className={styles.DeliverySlot}>
        <DeliverySlot />
      </div>
      <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
        {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
      </div>
    </header>
  );
};

export default Navbar;
