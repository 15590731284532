import { useNavigate } from "react-router-dom";
import CartData from "./CartData";
import Button from "../ui/Button";
import CardBackground from "../ui/CardBackground";
import { useCartList } from "../../store/CartContext";
import { useWishlist } from "../../store/WishlistContext";
import { useAuthContext } from "../../store/AuthContext";
import SaveForLater from "./SaveForLater";
import AlsoOrdered from "./AlsoOrdered";
import BuyItAgain from "./BuyItAgain";
import CustomTitle from "../ui/CustomTitle";
//import Deals from "./Deals";

const Cart = () => {
  const { cart } = useCartList();
  const { userId } = useAuthContext();
  const { wishlist } = useWishlist();
  const navigate = useNavigate();
  const total = cart
    .map((item) => Number(item.product_price * item.quantity))
    .reduce((prev, curr) => prev + curr, 0);
  //console.log(total, "total price");
  const CheckoutHandler = () => {
    if (userId) {
      navigate("/allSteps");
    } else {
      navigate("/auth");
    }
  };

  return (
    <div className="luxury">
      <CustomTitle>Your Cart</CustomTitle>
      <CardBackground>
        {cart.map((item, key) => (
          <CartData key={item.product_id} list={item} />
        ))}
      </CardBackground>
      <CardBackground>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <p>
            Subtotal ({cart.length} {cart.length > 1 ? "items" : " item"}) :{" "}
            <b> ₹{total} </b>{" "}
          </p>
          <Button type="submit" onClick={CheckoutHandler}>
            Proceed to Buy ({cart.length} {cart.length > 1 ? "items" : " item"})
          </Button>
        </div>
      </CardBackground>

      <div>
        {wishlist.length === 0 ? null : <SaveForLater list={wishlist} />}
      </div>
      <div>
        <AlsoOrdered />
      </div>
      <div>
        <BuyItAgain />
      </div>
      {/* <div>
        <Deals />
      </div> */}
    </div>
  );
};

export default Cart;
