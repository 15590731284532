import React, { useState, useEffect } from "react";
import styles from "./profile.module.css";
import CardBackground from "../../components/ui/CardBackground";
import { useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";
import userImage from "../../images/profile/profile.png";
import { useAuthContext } from "../../store/AuthContext";
import useAxios from "../../hooks/useAxios";
import Loader from "react-js-loader";

function YourAccount() {
  const navigate = useNavigate();

  const { userId, token, user } = useAuthContext();

  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      setTasks(tasksObj);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/Users/${userId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      transformTasks
    );
  }, [fetchTasks]);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }
  //console.log(tasks);
  return (
    <div className="luxury" style={{ maxWidth: "800px" }}>
      <CardBackground>
        <div>
          <img src={userImage} alt={userImage} />
          <h3> Hello {user}</h3>
        </div>

        {tasks.map((item, index) => (
          <div key={index} className={styles.contactInfo}>
            <div className={styles.contactItem}>
              <span className={styles.contactLabel}>Name: </span>
              <span className={styles.contactValue}>
                {item.user_firstname} {item.user_lastname}
              </span>
            </div>
            <div className={styles.contactItem}>
              <span className={styles.contactLabel}>Contact Number : </span>
              <span className={styles.contactValue}>
                {item.user_contact_number}
              </span>
            </div>
            <div className={styles.contactItem}>
              <span className={styles.contactLabel}>Email Id: </span>
              <span className={styles.contactValue}>{item.user_email}</span>
            </div>
            <div className={styles.contactItem}>
              <span className={styles.contactLabel}>Birthday : </span>
              <span className={styles.contactValue}>
                {item.user_dob.slice(0, 10)}
              </span>
            </div>
          </div>
        ))}
        <div style={{ width: "50%", padding: "auto", margin: "auto" }}>
          <Button
            type="submit"
            onClick={() =>
              navigate("/updateRegister", { state: { data: tasks } })
            }
          >
            Update Your Profile
          </Button>
        </div>
      </CardBackground>
    </div>
  );
}

export default YourAccount;
