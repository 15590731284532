import React from "react";
import EmptyBuy from "../../images/EmptyBuyAgain.png";
import CardBackground from "../../components/ui/CardBackground";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const EmptyBuyAgain = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={EmptyBuy}
          alt="EmptyBuyAgain"
          style={{ width: "25%", height: "25%" }}
        />
        <div>
          <h4>No Purchase History</h4>
          <p> Check back after your next shopping trip !</p>
        </div>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/")}>
            Shop Now
          </Button>
        </div>
      </CardBackground>
    </div>
  );
};

export default EmptyBuyAgain;
