import React, { useState } from "react";
import Modal from "../../components/ui/Modal";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import styles from "./ReviewPop.module.css";
import ReactStars from "react-stars";
import { AiOutlineClose } from "react-icons/ai";
import useInput from "../../hooks/use-input";
import { useReviews } from "../../store/ReviewContext";
import CustomTitle from "../../components/ui/CustomTitle";

const isNotEmpty = (value) => value.trim() !== "";

function ReviewPop(props) {
  const navigate = useNavigate();
  const { addReviews } = useReviews();
  const [rating, setRating] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(props.selectedItem);
  const [productId, setProductId] = useState(data.product_id);

  const {
    value: description,
    isValid: descriptionIsValid,
    hasError: descriptionHasError,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (descriptionIsValid || rating === 0) {
    formIsValid = true;
  }

  const saveReview = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    if (rating === 0) {
      setErrorMessage("Please select a rating before submitting!"); // Set the error message
    } else {
      setErrorMessage(""); // Clear the error message
      addReviews({ rating, description, productId });
      props.onCancel();
    }
  };

  function ratingChanged(newRating) {
    setRating(newRating);
  }

  const descriptionClasses = descriptionHasError
    ? "form-control invalid"
    : "form-control";

  return (
    <Modal visible={props.visible} onCancel={props.onCancel}>
      <div
        style={{ float: "right", cursor: "pointer" }}
        onClick={props.onCancel}
      >
        <AiOutlineClose size={25} />
      </div>
      <div className={styles.modalContent}>
        <CustomTitle>Write a Reviews</CustomTitle>
        <div className={styles.rows}>
          <div>
            <img
              src={data.product_img_uri}
              alt={data.product_name}
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div>
            <p>
              <b>{data.product_name}</b>
            </p>
            <p>
              <b>Brand:</b>
              {data.product_brand}
            </p>
            <p>
              <b>Type:</b>
              {data.product_quality}
            </p>
          </div>
        </div>

        <div>
          <label>Overall Rating</label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={44}
              color2={"#ffd700"}
            />
          </div>
          {errorMessage ? (
            <div className={styles.TextInputInvalid}>
              <p>{errorMessage}</p>
            </div>
          ) : null}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <form>
              <div className={styles.controlgroup}>
                <div className={styles.formcontrol}>
                  <div>
                    <label> Add a Written Review</label>
                  </div>

                  <div className={descriptionClasses}>
                    <textarea
                      placeholder="what did you like or dislike?"
                      value={description}
                      onChange={descriptionChangeHandler}
                      onBlur={descriptionBlurHandler}
                      rows={10}
                      cols={100}
                    />
                    {descriptionHasError && (
                      <p className="error-text"> Please add a written review</p>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
                <Button
                  type="submit"
                  onClick={saveReview}
                  disabled={!formIsValid}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ReviewPop;
