import React, { useState, useEffect } from "react";
import styles from "./LocationSearch.module.css";
import LocationPop from "./LocationPop";
import {
  AiOutlineSearch,
  AiTwotoneEnvironment,
  AiOutlineDown,
} from "react-icons/ai";
import { useAuthContext } from "../../../store/AuthContext";
import { useAddressList } from "../../../store/AddressContext";
import Colors from "../../ui/Colors";

function LocationSearch() {
  const [searchData, setSearchData] = useState("");
  const { address } = useAddressList();
  const { user, isAuthenticated } = useAuthContext();

  const [currentCity, setCurrentCity] = useState("Location");
  const [currentPinCode, setCurrentPinCode] = useState("PinCode");
  const [modalIsVisible, setModalIsVisible] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      setCurrentCity(address[0]?.city);
      setCurrentPinCode(address[0]?.pincode);
    }
  }, [isAuthenticated, address]);
  function addLocationHandler() {
    setModalIsVisible(true);
  }
  function closeLocationHandler() {
    setModalIsVisible(false);
  }

  function SearchChangeHandler(event) {
    setSearchData(event.target.value);
  }
  function selectedAddressHandler(item) {
    setCurrentPinCode(item.pincode);
    setCurrentCity(item.city);
  }
  function selectedPinHandler(item) {
    setCurrentPinCode(item);
  }
  function selectedCityHandler(item) {
    setCurrentCity(item);
  }
  function submitHandler(event) {
    event.preventDefault();

    //console.log(searchData);
  }
  //console.log(address);
  return (
    <div>
      <form className={styles.form} onSubmit={submitHandler}>
        <div className={styles.locationBtn} onClick={addLocationHandler}>
          <AiTwotoneEnvironment size={25} color={Colors.primary400} />
          <div>
            <p> {currentCity}</p>
            <p>{currentPinCode}</p>
          </div>
          <AiOutlineDown
            size={15}
            color={Colors.primary500}
            style={{ marginTop: "5px", marginRight: "5px" }}
          />
        </div>
        {modalIsVisible && (
          <LocationPop
            visible={modalIsVisible}
            onCancel={closeLocationHandler}
            selectedAddress={selectedAddressHandler}
            selectedPinCode={selectedPinHandler}
            selectedCity={selectedCityHandler}
          />
        )}
        <div className={styles.text}>
          <input
            className={styles.text_input}
            type="text"
            placeholder="Search..."
            onChange={SearchChangeHandler}
          />
        </div>

        <div className={styles.search_btn}>
          <AiOutlineSearch size={25} color={Colors.primary600} />
        </div>
      </form>
    </div>
  );
}

export default LocationSearch;
