// In your WishlistContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";
import axios from "axios";

const API_URL = "https://mason-api1.azurewebsites.net/api"; // replace with your backend URL

const ReviewContext = createContext();

export const useReviews = () => useContext(ReviewContext);

export const ReviewsProvider = ({ children }) => {
  const { token, userId } = useAuthContext();

  const [reviews, setReviews] = useState([]);

  const fetchReviews = async () => {
    try {
      const response = await axios.get(`${API_URL}/reviews`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const reviewItems = response.data;

      const productReviewsMap = reviewItems.reduce((acc, item) => {
        acc[item.product_id] = {
          average_rating: item.average_rating,
          comments: item.comments || [], // Fallback to an empty array if no comments are provided
          total_reviews: item.total_reviews,
          product_id: item.product_id,
        };
        return acc;
      }, {});

      setReviews(productReviewsMap);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    fetchReviews();
  }, []);

  const addReviews = async ({ rating, description, productId }) => {
    //console.log(productId, token, userId);
    try {
      const response = await axios.post(
        `${API_URL}/reviews`,
        {
          user_id: userId,
          product_id: productId,
          rating: rating,
          description: description,
        }, // Include userId and productId in the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newItem = response.data;
      setReviews(newItem);
      // console.log(reviews);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <ReviewContext.Provider value={{ reviews, addReviews, fetchReviews }}>
      {children}
    </ReviewContext.Provider>
  );
};
