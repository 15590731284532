import React, { useEffect, useState } from "react";
import styles from "./Menu.module.css";
import AccordianMenu from "./AccordianMenu";
import useAxios from "../../../hooks/useAxios";
import Loader from "react-js-loader";
import CustomTitle from "../../ui/CustomTitle";

const Menu = ({ navId }) => {
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          categoryId: tasksObj[taskKey].category_1_id,
          id: tasksObj[taskKey].category_2_id,
          name: tasksObj[taskKey].category_2_name,
          url: tasksObj[taskKey].category_2_img_uri,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/Category?categoryLevel=2`,
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  const NavigateHandler = (item) => {
    navId(item);
  };

  return (
    <div className={styles.luxury}>
      <CustomTitle>List of Categories </CustomTitle>
      <div>
        <AccordianMenu subCat={tasks} navId={NavigateHandler} />
      </div>
    </div>
  );
};

export default Menu;
