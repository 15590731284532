import React from "react";
import styles from "./Brands.module.css";
import { useNavigate } from "react-router-dom";

const Card = ({ image, make, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.card}
      onClick={() => {
        navigate(`/brandsOverview/${id}`);
      }}
    >
      <img src={image} alt="/" />
      <p>{make}</p>
    </div>
  );
};

export default Card;
