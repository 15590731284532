import React from "react";

function KitchenCleaning() {
  return (
    <div>
      <h2>Kitchen Cleaning</h2>
    </div>
  );
}

export default KitchenCleaning;
