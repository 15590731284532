import React from "react";

function Fastag() {
  return (
    <div>
      <h2>Fastag</h2>
    </div>
  );
}

export default Fastag;
