import React from "react";

function DTH() {
  return (
    <div>
      <h2>DTH</h2>
    </div>
  );
}

export default DTH;
