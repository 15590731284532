import React, { useState, useEffect } from "react";
import styles from "./ProductsPage.module.css";
import Products from "../../components/home/categories/Products";
import Menu from "../../components/sidebar/menu/Menu";
import Filters from "../../components/sidebar/filters/Filters";
import BrandFilters from "../../components/sidebar/filters/BrandFilters";
import EmptyFilter from "../../components/sidebar/filters/EmptyFilter";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useReviews } from "../../store/ReviewContext";
import BrandsMenu from "../../components/sidebar/brandsmenu/BrandsMenu";

function ProductsPage({ dataList, catId, BrandsList }) {
  //console.log(BrandsList);
  const [nav, setNav] = useState(false);
  const [list, setList] = useState(dataList);
  const [resultsFound, setResultsFound] = useState(true);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedRating, setSelectedRating] = useState(null);
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [selectedId, setSelectedId] = useState(null);
  const { reviews } = useReviews();
  function submitHandler(data) {
    //console.log(data);
    setSelectedType(data.typesCheckedBox);
    setSelectedBrand(data.brandsCheckedBox);
    setSelectedRating(data.rating);
    setMinPrice(data.minValue);
    setMaxPrice(data.maxValue);
  }

  const HideResultHandler = () => {
    setSelectedType([]);
    setSelectedBrand([]);
    setSelectedRating("");
    setMinPrice(1);
    setMaxPrice(1000);
  };

  function MenuHandler(item) {
    setSelectedId(item);
    HideResultHandler();
  }

  const applyFilters = () => {
    let updatedList = dataList;
    //console.log(updatedList);
    //Menu
    if (selectedId) {
      updatedList = updatedList.filter(
        (item) => item.categoryId === selectedId.id
      );
    }
    /*   // Search Filter
    if (searchInput) {
      updatedList = updatedList.filter(
        (item) =>
          item.name.toLowerCase().search(searchInput.toLowerCase().trim()) !==
          -1
      );
    } */

    // Rating Filter

    if (selectedRating) {
      //const reviewArray = Object.values(reviews);
      const targetItem = reviews
        ? Object.values(reviews).find(
            (item) =>
              item.average_rating === parseFloat(selectedRating).toFixed(1)
          )
        : undefined;

      updatedList = updatedList.filter(
        (item) => item.product_id === targetItem.product_id
      );
    }

    // Brand Filter

    if (selectedBrand?.length) {
      updatedList = updatedList.filter((item) =>
        selectedBrand?.includes(item.product_brand)
      );
    }
    // Type Filter

    if (selectedType?.length) {
      updatedList = updatedList.filter((item) =>
        selectedType?.includes(item.product_quality)
      );
    }

    // Price Filter
    if (minPrice && maxPrice) {
      updatedList = updatedList.filter(
        (item) =>
          item.product_price >= minPrice && item.product_price <= maxPrice
      );
    }

    setList(updatedList);

    !updatedList?.length ? setResultsFound(false) : setResultsFound(true);
  };

  useEffect(() => {
    applyFilters();
  }, [
    selectedRating,
    selectedBrand,
    selectedType,
    minPrice,
    maxPrice,
    selectedId,
  ]);

  return (
    <div className={styles.article}>
      <div className={styles.menuBlock}>
        <div
          className={
            nav ? [styles.menu, styles.active].join(" ") : [styles.menu]
          }
        >
          <div>
            {BrandsList === "false" ? (
              <Menu navId={MenuHandler} />
            ) : (
              <BrandsMenu />
            )}
          </div>
        </div>
        <div>
          {BrandsList === "false" ? (
            <Filters
              onSubmit={submitHandler}
              onCancel={HideResultHandler}
              catId={catId}
            />
          ) : (
            <BrandFilters
              onCancel={HideResultHandler}
              onSubmit={submitHandler}
            />
          )}
        </div>
      </div>
      <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
        {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
      </div>
      {resultsFound ? (
        <div>
          <Products data={list} />
        </div>
      ) : (
        <div>
          <EmptyFilter />
        </div>
      )}
    </div>
  );
}

export default ProductsPage;
