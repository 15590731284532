import React from 'react'
import Login from '../../components/login/Login';



function LoginPage() {
  return (
    <div>         
           <Login/>    
    </div>
  );
}

export default LoginPage;
