import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Button";
import WhiteButton from "../ui/WhiteButton";
import CardBackground from "../ui/CardBackground";
import { useCartList } from "../../store/CartContext";

const AddtoCart = ({ item }) => {
  const navigate = useNavigate();
  const { cart } = useCartList();

  const total = cart
    .map((item) => Number(item.price * item.quantity))
    .reduce((prev, curr) => prev + curr, 0);
  //console.log(total, "total price");
  // console.log(item);

  return (
    <div className="luxury">
      <CardBackground>
        <img src={item.product_img_uri} alt={item.product_name} />
        <div className="contentDirection">
          <AiFillCheckCircle size={30} color="#F6C540" />
          <h3>Added to Cart</h3>
        </div>
      </CardBackground>

      <CardBackground>
        <p>
          Cart subtotal: <b> ₹{total}</b>
        </p>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/allSteps")}>
            Proceed to Buy ({cart.length} {cart.length > 1 ? "items" : " item"})
          </Button>
          <WhiteButton type="submit" onClick={() => navigate("/Mycart")}>
            Go to Cart
          </WhiteButton>
        </div>
      </CardBackground>
    </div>
  );
};

export default AddtoCart;
