import React from "react";

function MuncipalServices() {
  return (
    <div>
      <h2>MuncipalServices</h2>
    </div>
  );
}

export default MuncipalServices;
