import Category from "../models/Category";
import MainCategory from "../models/MainCategory";
import Categorieslist from "../models/CategoriesList";
import OrderList from "../models/OrderList";
import Brands from "../models/Brands";
import Address from "../models/Address";
import Services from "../models/Services";
import SubService from "../models/SubService";
import YourBills from "../models/YourBills";
import OtherSubService from "../models/OtherSubService";

export const MAINCATEGORIES = [
  new MainCategory(
    "mc1",
    "Building Material",
    "https://www.re-thinkingthefuture.com/wp-content/uploads/2020/06/A1075-Web-10-Different-construction-materials-around-the-globe.jpg"
  ),
  new MainCategory(
    "mc2",
    "Electrical",
    "https://theconstructor.org/wp-content/uploads/2021/07/4-Types-of-Tests-conducted-on-Internal-Electrical-Installations.jpg"
  ),
  new MainCategory(
    "mc3",
    "Plumbing",
    "https://content.jdmagicbox.com/comp/hyderabad/q9/040pxx40.xx40.200729094320.j6q9/catalogue/maa-durga-plumber-hyderabad-0gi2rizn6a.jpg?clr="
  ),
  new MainCategory(
    "mc4",
    "Plywood & Planks",
    "https://3.imimg.com/data3/CT/UQ/MY-13213436/ply-board-250x250.jpg"
  ),
  new MainCategory(
    "mc7",
    "Floor & Wall",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ7ox_Eusu-9RuM1RC5fACbsCC8FsuvuC1zw&usqp=CAU"
  ),
  new MainCategory(
    "mc8",
    "Doors & Window",
    "https://5.imimg.com/data5/PU/ND/MY-61154934/wooden-stool-250x250.jpg"
  ),
];

export const CATEGORIES = [
  new Category(
    "c1",
    "mc1",
    "Cement",
    "https://img.freepik.com/premium-photo/cement-bags-paper-sacks-isolated-white-background_104576-214.jpg?w=2000"
  ),
  new Category(
    "c2",
    "mc1",
    "Bricks",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREgyPRIS4eX-lN7QFZUrUyjVd36H689SC0UA&usqp=CAU"
  ),
  new Category(
    "c3",
    "mc1",
    "Iron Rods",
    "https://media.istockphoto.com/photos/metal-armature-metal-rods-3d-illustration-picture-id906641610?b=1&k=20&m=906641610&s=170667a&w=0&h=B7-ihRFTP4iJ8EwwL4FxA_yV4eZTNHN9Ljl1tOc09ug="
  ),
  new Category(
    "c4",
    "mc7",
    "Tiles",
    "https://www.lycosceramic.com/wp-content/uploads/2021/12/Top-5-Tile-Finish-Which-One-Is-Right-For-Your-House.jpg"
  ),
  new Category(
    "c7",
    "mc3",
    "Pipes",
    "https://5.imimg.com/data5/UO/UI/MY-14962714/finolex-pvc-pipe-1000x1000.jpg"
  ),
  new Category(
    "c8",
    "mc7",
    "Paints",
    "https://images.moneycontrol.com/static-mcnews/2017/04/Paints-e1548221251733.jpg?impolicy=website&width=770&height=431"
  ),
  new Category(
    "c9",
    "mc2",
    "Electrician",
    "https://content.jdmagicbox.com/comp/thiruvananthapuram/m4/0471px471.x471.140619160942.u6m4/catalogue/mathew-electricals-and-enterprises-kudappanakkunnu-thiruvananthapuram-electrical-shops-1ylqqjtz9s.jpg"
  ),
  new Category(
    "c10",
    "mc3",
    "Plumbing",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVAHA07sfLiZUwx0uirpviieFl30IjBMrT0Q&usqp=CAU"
  ),
  new Category(
    "c11",
    "mc2",
    "Hardware",
    "https://www.williamsongreenhouses.com/wp-content/uploads/2020/11/img-hardware.jpg"
  ),
  new Category(
    "c12",
    "mc4",
    "PlayWood",
    "https://img.freepik.com/premium-photo/cement-bags-paper-sacks-isolated-white-background_104576-214.jpg?w=2000"
  ),
  new Category(
    "c13",
    "mc4",
    "Bricks",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREgyPRIS4eX-lN7QFZUrUyjVd36H689SC0UA&usqp=CAU"
  ),
  new Category(
    "c14",
    "mc4",
    "Planks",
    "https://3.imimg.com/data3/CT/UQ/MY-13213436/ply-board-250x250.jpg"
  ),
  new Category(
    "c15",
    "mc7",
    "NewTiles",
    "https://www.lycosceramic.com/wp-content/uploads/2021/12/Top-5-Tile-Finish-Which-One-Is-Right-For-Your-House.jpg"
  ),
  new Category(
    "c16",
    "mc3",
    "Water Storage Tanks",
    "https://5.imimg.com/data5/UO/UI/MY-14962714/finolex-pvc-pipe-1000x1000.jpg"
  ),
  new Category(
    "c17",
    "mc7",
    "Water Paints",
    "https://images.moneycontrol.com/static-mcnews/2017/04/Paints-e1548221251733.jpg?impolicy=website&width=770&height=431"
  ),
  new Category(
    "c18",
    "mc8",
    "Door",
    "https://content.jdmagicbox.com/comp/thiruvananthapuram/m4/0471px471.x471.140619160942.u6m4/catalogue/mathew-electricals-and-enterprises-kudappanakkunnu-thiruvananthapuram-electrical-shops-1ylqqjtz9s.jpg"
  ),
  new Category(
    "c19",
    "mc8",
    "Windows",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVAHA07sfLiZUwx0uirpviieFl30IjBMrT0Q&usqp=CAU"
  ),
  new Category(
    "c20",
    "mc8",
    "Doors & Windows",
    "https://5.imimg.com/data5/PU/ND/MY-61154934/wooden-stool-250x250.jpg"
  ),
];

export const CATEGORIESLIST = [
  new Categorieslist(
    "m1",
    ["c1"],
    "Cement1",
    "3",
    "Ramco Cement",
    "43 Grade Cement",
    "https://img.freepik.com/premium-photo/cement-bags-paper-sacks-isolated-white-background_104576-214.jpg?w=2000",
    200,
    "A cement is a binder, a chemical substance used for construction that sets, hardens,"
  ),
  new Categorieslist(
    "m2",
    ["c1"],
    "Cement2",
    "4",
    "UltraTech Cement",
    "53 Grade Cement",
    "https://img.freepik.com/premium-photo/cement-bags-paper-sacks-isolated-white-background_104576-214.jpg?w=2000",
    120,
    "A cement is a binder, a chemical substance used for construction that sets, hardens, and adheres to other materials to bind them together. Cement is seldom used on its own, but rather to bind sand and gravel (aggregate) together."
  ),
  new Categorieslist(
    "m31",
    ["c1"],
    "Cement3",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://img.freepik.com/premium-photo/cement-bags-paper-sacks-isolated-white-background_104576-214.jpg?w=2000",
    230,
    "A cement is a binder, a chemical substance used for construction that sets, hardens, and adheres to other materials to bind them together. Cement is seldom used on its own, but rather to bind sand and gravel (aggregate) together. Cement mixed with fine aggregate produces mortar for masonry, or with sand and gravel, produces concrete. Concrete is the most widely used material in existence and is behind only water as the planet most-consumed resource."
  ),
  new Categorieslist(
    "m39",
    ["c1"],
    "Cement4",
    "5",
    "Ramco Cement",
    "Portland Pozzolana Cement",
    "https://img.freepik.com/premium-photo/cement-bags-paper-sacks-isolated-white-background_104576-214.jpg?w=2000",
    220,
    "A cement is a binder, a chemical substance used for construction that sets, hardens, and adheres to other materials to bind them together. Cement is seldom used on its own, but rather to bind sand and gravel (aggregate) together. Cement mixed with fine aggregate produces mortar for masonry, or with sand and gravel, produces concrete. Concrete is the most widely used material in existence and is behind only water as the planet most-consumed resource."
  ),
  new Categorieslist(
    "m3",
    ["c2"],
    "Bricks1",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREgyPRIS4eX-lN7QFZUrUyjVd36H689SC0UA&usqp=CAU",
    10,
    "A brick is a type of block used to build walls, pavements and other elements in masonry construction. Properly, the term brick denotes a block composed of dried clay, but is now also used informally to denote other chemically cured construction blocks."
  ),

  new Categorieslist(
    "m4",
    ["c2"],
    "Bricks2",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREgyPRIS4eX-lN7QFZUrUyjVd36H689SC0UA&usqp=CAU",
    45,
    "A brick is a type of block used to build walls, pavements and other elements in masonry construction. Properly, the term brick denotes a block composed of dried clay, but is now also used informally to denote other chemically cured construction blocks. Bricks can be joined using mortar, adhesives or by interlocking them.[1][2] Bricks are produced in numerous classes, types, materials, and sizes which vary with region and time period, and are produced in bulk quantities."
  ),

  new Categorieslist(
    "m5",
    ["c3"],
    "Iron Rods",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://media.istockphoto.com/photos/metal-armature-metal-rods-3d-illustration-picture-id906641610?b=1&k=20&m=906641610&s=170667a&w=0&h=B7-ihRFTP4iJ8EwwL4FxA_yV4eZTNHN9Ljl1tOc09ug=",
    60,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),

  new Categorieslist(
    "m6",
    ["c3"],
    "Iron Rods",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://media.istockphoto.com/photos/metal-armature-metal-rods-3d-illustration-picture-id906641610?b=1&k=20&m=906641610&s=170667a&w=0&h=B7-ihRFTP4iJ8EwwL4FxA_yV4eZTNHN9Ljl1tOc09ug=",
    15,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),

  new Categorieslist(
    "m7",
    ["c4"],
    "Tiles",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://www.lycosceramic.com/wp-content/uploads/2021/12/Top-5-Tile-Finish-Which-One-Is-Right-For-Your-House.jpg",
    240,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),

  new Categorieslist(
    "m8",
    ["c4"],
    "Tiles",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "c",
    20,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),

  new Categorieslist(
    "m9",
    ["c5"],
    "Stone",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://sc04.alicdn.com/kf/H421a4d6be51445b8865724f741a0a348U.jpg",
    35,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),

  new Categorieslist(
    "m10",
    ["c5"],
    "Stone",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://sc04.alicdn.com/kf/H421a4d6be51445b8865724f741a0a348U.jpg",
    45,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),
  new Categorieslist(
    "m11",
    ["c6"],
    "Sand",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://images.thdstatic.com/productImages/371bf27d-0927-4b55-899b-1189b228a2f9/svn/yard-elements-landscape-accessories-02-0507-64_300.jpg",
    30,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),
  new Categorieslist(
    "m12",
    ["c6"],
    "Sand",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://images.thdstatic.com/productImages/371bf27d-0927-4b55-899b-1189b228a2f9/svn/yard-elements-landscape-accessories-02-0507-64_300.jpg",
    30,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),
  new Categorieslist(
    "m13",
    ["c7"],
    "Astral Pipes",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://5.imimg.com/data5/UO/UI/MY-14962714/finolex-pvc-pipe-1000x1000.jpg",
    30,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),
  new Categorieslist(
    "m14",
    ["c7"],
    "Ashirvad pipes",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://5.imimg.com/data5/UO/UI/MY-14962714/finolex-pvc-pipe-1000x1000.jpg",
    30,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),
  new Categorieslist(
    "m15",
    ["c8"],
    "Asian Paints",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://images.moneycontrol.com/static-mcnews/2017/04/Paints-e1548221251733.jpg?impolicy=website&width=770&height=431",
    30,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),
  new Categorieslist(
    "m16",
    ["c8"],
    "Burger",
    "3",
    "ACC Cement",
    "53 Grade Cement",
    "https://images.moneycontrol.com/static-mcnews/2017/04/Paints-e1548221251733.jpg?impolicy=website&width=770&height=431",
    30,
    "In olden days, houses across India were built with iron rods and concrete slabs. The rods would begin to rust soon at early stage. It affects the stability of the structure in the extensive run.  This leads to a frequent repair of the house."
  ),
];

export const ORDERLIST = [
  new OrderList(
    "o1",
    "m1",
    "Cement1",
    "https://img.freepik.com/premium-photo/cement-bags-paper-sacks-isolated-white-background_104576-214.jpg?w=2000",
    "1-Dec-2022",
    "7-Dec-2022",
    "2554",
    "10",
    "250",
    "300",
    "Asian Paints"
  ),
  new OrderList(
    "o2",
    "m14",
    "Ashirvad pipes",
    "https://5.imimg.com/data5/UO/UI/MY-14962714/finolex-pvc-pipe-1000x1000.jpg",
    "15-Oct-2022",
    "20-Oct-2022",
    "3554",
    "20",
    "350",
    "400",
    "ACC Cement"
  ),
  new OrderList(
    "o3",
    "m9",
    "Stone",
    "https://sc04.alicdn.com/kf/H421a4d6be51445b8865724f741a0a348U.jpg",
    "4-Jul-2022",
    "7-Jul-2022",
    "2500",
    "10",
    "100",
    "350",
    "Astral Pipes"
  ),
  new OrderList(
    "o4",
    "m7",
    "Tiles",
    "https://www.lycosceramic.com/wp-content/uploads/2021/12/Top-5-Tile-Finish-Which-One-Is-Right-For-Your-House.jpg",
    "21-Feb-2022",
    "25-Feb-2022",
    "1500",
    "8",
    "150",
    "200",
    "Ramco Cement"
  ),
  new OrderList(
    "o5",
    "m4",
    "Bricks",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREgyPRIS4eX-lN7QFZUrUyjVd36H689SC0UA&usqp=CAU",
    "10-Jan-2022",
    "17-Jan-2022",
    "2000",
    "5",
    "150",
    "100",
    "Ultra Cement"
  ),
];
export const BRANDS = [
  new Brands(
    "b1",
    "Ambuja Cement",
    "https://5.imimg.com/data5/UF/OF/GLADMIN-8486930/ambuja-cement-india-500x500.png"
  ),
  new Brands(
    "b2",
    "Berger",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkqsz3j7lRvdYp7R3C0i4HP84vWNPz013jxJ2bkDb6wN1zin5Zyegv14N2sRSket2F-HM&usqp=CAU"
  ),
  new Brands(
    "b3",
    "Astral Pipes",
    "https://www.mostinside.com/wp-content/uploads/2016/08/Best-Brands-of-CPVC-Pipes-in-India-1.jpg"
  ),
  new Brands("b4", "Cera", "https://i.ytimg.com/vi/eKHO4f81-WQ/hqdefault.jpg"),
  new Brands(
    "b5",
    "Tata Steel",
    "https://top10tmtbrandsindia.com/tata-tiscon.jpg"
  ),
  new Brands(
    "b6",
    "Asian Paints",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKW2n1NVdW9OJlFbUvL-igyOWCSKojZpG58RSVrozp1851NXsbk-GizWYeeZWLTuUw9Dg&usqp=CAU"
  ),
];

export const ADDRESS = [
  new Address(
    "1",
    "16",
    "first Name",
    "Middle Name",
    "Last Name",
    "3333333333",
    "email.com",
    "Address",
    "pinCode",
    "Hyderabad",
    "RangaReddy",
    "India"
  ),
  new Address(
    "2",
    "16",
    "first Name",
    "Middle Name",
    "Last Name",
    "3333333333",
    "email.com",
    "Address",
    "pinCode",
    "Hyderabad",
    "RangaReddy",
    "India"
  ),
  new Address(
    "3",
    "16",
    "first Name",
    "Middle Name",
    "Last Name",
    "3333333333",
    "email.com",
    "Address",
    "pinCode",
    "Hyderabad",
    "RangaReddy",
    "India"
  ),
];

export const SERVICES = [
  new Services(
    "1",
    require("../images/services/mainPayCategory/payBills.png"),
    "Pay Bills",
    "PayBills"
  ),
  new Services(
    "2",
    require("../images/services/mainPayCategory/labour.png"),
    "Labour Services",
    "LabourServices"
  ),
  new Services(
    "4",
    require("../images/services/mainPayCategory/recharge.png"),
    "Recharge",
    "Recharge"
  ),
  new Services(
    "5",
    require("../images/services/mainPayCategory/cleaning.png"),
    "Cleaning",
    "Cleaning"
  ),
  new Services(
    "6",
    require("../images/services/mainPayCategory/ACService.png"),
    "AC Service",
    "ACService"
  ),
  new Services(
    "7",
    require("../images/services/mainPayCategory/payrent.png"),
    "Pay Rent",
    "PayRent"
  ),

  new Services(
    "8",
    require("../images/services/mainPayCategory/sendMoney.png"),
    "Send Money",
    "SendMoney"
  ),
  new Services(
    "9",
    require("../images/services/mainPayCategory/packerMovers.png"),
    "Packer Movers",
    "PackerMovers"
  ),
];

export const SUBSERVICES = [
  new SubService(
    "0",
    "1",
    require("../images/services/payBills/electric_bill.png"),
    "Electricity Bill",
    "ElectricBill"
  ),
  new SubService(
    "1",
    "1",
    require("../images/services/payBills/gas.png"),
    "Book Gas Cylinder",
    "GasBill"
  ),
  new SubService(
    "2",
    "1",
    require("../images/services/payBills/creditCard.png"),
    "Credit Card Bill",
    "CreditCard"
  ),
  new SubService(
    "3",
    "1",
    require("../images/services/payBills/mobilePost.png"),
    "Mobile Postpaid",
    "MobilePost"
  ),
  new SubService(
    "4",
    "1",
    require("../images/services/payBills/insurance.png"),
    "Insurance Premium",
    "Insurance"
  ),
  new SubService(
    "5",
    "1",
    require("../images/services/payBills/broadBand.png"),
    "Broadband",
    "BroadBand"
  ),
  new SubService(
    "6",
    "1",
    require("../images/services/payBills/pippedGas.png"),
    "Piped Gas",
    "PippedGas"
  ),
  new SubService(
    "7",
    "1",
    require("../images/services/payBills/water.png"),
    "Water",
    "Water"
  ),
  new SubService(
    "8",
    "1",
    require("../images/services/payBills/loan.png"),
    "Loan Repayment",
    "LoanRepayment"
  ),
  new SubService(
    "9",
    "1",
    require("../images/services/payBills/landline.png"),
    "Landline",
    "Landline"
  ),
  new SubService(
    "10",
    "1",
    require("../images/services/payBills/municipality.png"),
    "Muncipal Tax",
    "Municipality"
  ),
  new SubService(
    "11",
    "1",
    require("../images/services/payBills/education.png"),
    "Education Fees",
    "EducationFees"
  ),
  new SubService(
    "12",
    "1",
    require("../images/services/payBills/rent.png"),
    "Pay Rent",
    "PayRent"
  ),
  new SubService(
    "13",
    "1",
    require("../images/services/payBills/municipal_services.png"),
    "Muncipal Services",
    "MuncipalServices"
  ),
  new SubService(
    "14",
    "1",
    require("../images/services/payBills/hospital.png"),
    "Hospital",
    "Hospital"
  ),
  new SubService(
    "15",
    "2",
    require("../images/services/labour/electrician.png"),
    "Electrician",
    "Electrician"
  ),
  new SubService(
    "16",
    "2",
    require("../images/services/labour/carpentry.png"),
    "Carpentry",
    "Carpentry"
  ),
  new SubService(
    "17",
    "2",
    require("../images/services/labour/Plumbing.png"),
    "Plumbing",
    "Plumbing"
  ),
  new SubService(
    "18",
    "2",
    require("../images/services/labour/painting.png"),
    "Painting",
    "Painting"
  ),
  new SubService(
    "19",
    "4",
    require("../images/services/recharge/mobile.png"),
    "Mobile",
    "Mobile"
  ),
  new SubService(
    "20",
    "4",
    require("../images/services/recharge/dth.png"),
    "DTH",
    "DTH"
  ),
  new SubService(
    "21",
    "4",
    require("../images/services/recharge/Fastag.png"),
    "Fastag",
    "Fastag"
  ),
  new SubService(
    "22",
    "4",
    require("../images/services/recharge/MetroRecharge.png"),
    "Metro Recharge",
    "MetroRecharge"
  ),
  new SubService(
    "23",
    "4",
    require("../images/services/recharge/AppStore.png"),
    "AppStore Code",
    "AppStore"
  ),
  new SubService(
    "24",
    "4",
    require("../images/services/recharge/Google.png"),
    "Google Play",
    "Google"
  ),
  new SubService(
    "25",
    "4",
    require("../images/services/recharge/Subscriptions.png"),
    "Subscriptions",
    "Subscriptions"
  ),
  new SubService(
    "26",
    "4",
    require("../images/services/recharge/Cable.png"),
    "Cable TV",
    "Cable"
  ),
  new SubService(
    "27",
    "5",
    require("../images/services/cleaning/HomeCleaning.png"),
    "Home Cleaning",
    "HomeCleaning"
  ),
  new SubService(
    "28",
    "5",
    require("../images/services/cleaning/SofaCleaning.png"),
    "Sofa Cleaning",
    "SofaCleaning"
  ),
  new SubService(
    "29",
    "5",
    require("../images/services/cleaning/BathroomCleaning.png"),
    "Bathroom Cleaning",
    "BathroomCleaning"
  ),
  new SubService(
    "30",
    "5",
    require("../images/services/cleaning/WeeklyCleaning.png"),
    "Weekly Cleaning",
    "WeeklyCleaning"
  ),
  new SubService(
    "31",
    "5",
    require("../images/services/cleaning/KitchenCleaning.png"),
    "Kitchen Cleaning",
    "KitchenCleaning"
  ),
  new SubService(
    "32",
    "5",
    require("../images/services/cleaning/PremiumCleaning.png"),
    "Premium Cleaning",
    "PremiumCleaning"
  ),
  new SubService(
    "33",
    "6",
    require("../images/services/AC/AcService.png"),
    "AC Service",
    "AcService"
  ),
  new SubService(
    "34",
    "6",
    require("../images/services/AC/AcGas.png"),
    "AC Gas Charging",
    "AcGas"
  ),
  new SubService(
    "35",
    "6",
    require("../images/services/AC/AcInstallation.png"),
    "AC Installation",
    "AcInstallation"
  ),
  new SubService(
    "36",
    "6",
    require("../images/services/AC/AcRepair.png"),
    "AC Repair",
    "AcRepair"
  ),
];
export const YOURBILLS = [
  new YourBills(
    "1",
    "Prepaid",
    require("../images/services/yourBills/jio.png"),
    "1234567890"
  ),
  new YourBills(
    "2",
    "creditcard",
    require("../images/services/yourBills/bank.png"),
    "1234567890"
  ),
  new YourBills(
    "3",
    "BroadBand",
    require("../images/services/yourBills/broadband.png"),
    "1234567890"
  ),
  new YourBills(
    "4",
    "Electricity",
    require("../images/services/yourBills/Electicity.png"),
    "1234567890"
  ),
  new YourBills(
    "5",
    "Prepaid",
    require("../images/services/yourBills/airtel.png"),
    "1234567890"
  ),
];

export const OTHERSUBSERVICES = [
  /* Electrician*/
  new OtherSubService(
    "0",
    "15",
    require("../images/services/labour/electrician/Refrigerator.png"),
    "Refrigerator",
    "Refrigerator"
  ),
  new OtherSubService(
    "1",
    "15",
    require("../images/services/labour/electrician/Microwave.png"),
    "Microwave",
    "Microwave"
  ),
  new OtherSubService(
    "2",
    "15",
    require("../images/services/labour/electrician/WaterPurifier.png"),
    "Water Purifier",
    "WaterPurifier"
  ),
  new OtherSubService(
    "3",
    "15",
    require("../images/services/labour/electrician/WashingMachine.png"),
    "Washing Machine",
    "WashingMachine"
  ),
  new OtherSubService(
    "4",
    "15",
    require("../images/services/labour/electrician/Fan.png"),
    "Fan",
    "Fan"
  ),
  new OtherSubService(
    "5",
    "15",
    require("../images/services/labour/electrician/SwitchBoardSockets.png"),
    "Switch Board/Sockets",
    "SwitchBoardSockets"
  ),
  new OtherSubService(
    "6",
    "15",
    require("../images/services/labour/electrician/Geyser.png"),
    "Geyser",
    "Geyser"
  ),
  new OtherSubService(
    "7",
    "15",
    require("../images/services/labour/electrician/TubeLightBulb.png"),
    "TubeLight/Bulb",
    "TubeLightBulb"
  ),
  new OtherSubService(
    "8",
    "15",
    require("../images/services/labour/electrician/TV.png"),
    "TV",
    "TV"
  ),
  new OtherSubService(
    "9",
    "15",
    require("../images/services/labour/electrician/MCBFuse.png"),
    "MCB/Fuse",
    "MCBFuse"
  ),
  new OtherSubService(
    "10",
    "15",
    require("../images/services/labour/electrician/Wiring.png"),
    "Wiring",
    "Wiring"
  ),
  new OtherSubService(
    "11",
    "15",
    require("../images/services/labour/electrician/InverterStablizer.png"),
    "Inverter/Stablizer",
    "InverterStablizer"
  ),
  new OtherSubService(
    "12",
    "15",
    require("../images/services/labour/electrician/DoorBell.png"),
    "Door Bell",
    "DoorBell"
  ),
  /*carpentry*/
  new OtherSubService(
    "13",
    "16",
    require("../images/services/labour/carpentry/CupBoardDrawer.png"),
    "CupBoard & Drawer",
    "CupBoardDrawer"
  ),
  new OtherSubService(
    "14",
    "16",
    require("../images/services/labour/carpentry/Balcony.png"),
    "Balcony",
    "Balcony"
  ),
  new OtherSubService(
    "15",
    "16",
    require("../images/services/labour/carpentry/Bed.png"),
    "Bed",
    "Bed"
  ),
  new OtherSubService(
    "16",
    "16",
    require("../images/services/labour/carpentry/Door.png"),
    "Door",
    "Door"
  ),
  new OtherSubService(
    "17",
    "16",
    require("../images/services/labour/carpentry/DrillHang.png"),
    "Drill & Hang",
    "DrillHang"
  ),
  new OtherSubService(
    "18",
    "16",
    require("../images/services/labour/carpentry/FurnitureRepair.png"),
    "Furniture Repair",
    "FurnitureRepair"
  ),
  new OtherSubService(
    "19",
    "16",
    require("../images/services/labour/carpentry/TV.png"),
    "TV",
    "TV"
  ),
  new OtherSubService(
    "20",
    "16",
    require("../images/services/labour/carpentry/WindowsCurtains.png"),
    "Windows & Curtains",
    "WindowsCurtains"
  ),
  new OtherSubService(
    "21",
    "16",
    require("../images/services/labour/carpentry/looking.png"),
    "Looking for something else",
    "looking"
  ),
  new OtherSubService(
    "22",
    "16",
    require("../images/services/labour/carpentry/Full.png"),
    "Full Home Checkup",
    "Full"
  ),

  /* plumbing*/
  new OtherSubService(
    "23",
    "17",
    require("../images/services/labour/Plumbing/BasinSink.png"),
    "Basin & Sink",
    "BasinSink"
  ),
  new OtherSubService(
    "24",
    "17",
    require("../images/services/labour/Plumbing/Grounting.png"),
    "Grounting",
    "Grounting"
  ),
  new OtherSubService(
    "25",
    "17",
    require("../images/services/labour/Plumbing/BathFitting.png"),
    "Bath Fitting",
    "BathFitting"
  ),
  new OtherSubService(
    "26",
    "17",
    require("../images/services/labour/Plumbing/DrainagePipes.png"),
    "Drainage Pipes",
    "DrainagePipes"
  ),
  new OtherSubService(
    "27",
    "17",
    require("../images/services/labour/Plumbing/Toilet.png"),
    "Toilet",
    "Toilet"
  ),
  new OtherSubService(
    "28",
    "17",
    require("../images/services/labour/Plumbing/TapMixer.png"),
    "Tap And Mixer",
    "TapMixer"
  ),
  new OtherSubService(
    "29",
    "17",
    require("../images/services/labour/Plumbing/WaterTank.png"),
    "Water Tank",
    "WaterTank"
  ),
  new OtherSubService(
    "30",
    "17",
    require("../images/services/labour/Plumbing/Motor.png"),
    "Motor",
    "Motor"
  ),
  new OtherSubService(
    "31",
    "17",
    require("../images/services/labour/Plumbing/WaterConnection.png"),
    "Water Pipe Connection",
    "WaterConnection"
  ),
  new OtherSubService(
    "32",
    "17",
    require("../images/services/labour/Plumbing/looking.png"),
    "Looking for something else",
    "looking"
  ),
  /*Painting */
  new OtherSubService(
    "33",
    "18",
    require("../images/services/labour/painting/InteriorPainting.png"),
    "Interior Painting",
    "InteriorPainting"
  ),
  new OtherSubService(
    "34",
    "18",
    require("../images/services/labour/painting/ExteriorPainting.png"),
    "Exterior Painting",
    "ExteriorPainting"
  ),
  new OtherSubService(
    "35",
    "18",
    require("../images/services/labour/painting/WateProofing.png"),
    "Water Proofing",
    "WateProofing"
  ),
  new OtherSubService(
    "36",
    "18",
    require("../images/services/labour/painting/RentalPainting.png"),
    "Rental Painting",
    "RentalPainting"
  ),
  new OtherSubService(
    "37",
    "18",
    require("../images/services/labour/painting/OneWallPainting.png"),
    "One Wall Painting",
    "OneWallPainting"
  ),
];
