import React from "react";
import styles from "./PrivacyPolicy.module.css";
import CustomTitle from "../../components/ui/CustomTitle";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <CustomTitle>Privacy Policy</CustomTitle>

      <p>
        <strong>Effective Date:</strong> 03-03-2025
      </p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to <b> Cooli</b> . Your privacy is important to us. This Privacy
        Policy explains how we collect, use, and protect your information when
        you use our application Cooli.
      </p>

      <h2>2. Information We Collect</h2>

      <p>
        <strong>Personal Information:</strong> Name, email address, phone
        number, or other information you provide.
      </p>
      <p>
        <strong>Usage Data:</strong> Information about how you use the App,
        including interactions, features accessed, and time spent.
      </p>
      <p>
        <strong>Device Information:</strong> Device type, operating system, IP
        address, and unique device identifiers.
      </p>
      <p>
        <strong>Location Data:</strong> If you allow, we may collect and use
        your location data to enhance the app experience.
      </p>

      <h2>3. How We Use Your Information</h2>

      <p>To provide and improve our services.</p>
      <p>To personalize user experience.</p>
      <p>
        To send important updates, notifications, or promotional content (with
        your consent).
      </p>
      <p>To monitor and analyze usage patterns to enhance app performance.</p>
      <p>To comply with legal obligations.</p>

      <h2>4. Sharing Your Information</h2>
      <p>
        We do not sell or rent your personal information. However, we may share
        your information in the following cases:
      </p>

      <p>
        <strong>Service Providers:</strong> We may share data with third-party
        service providers who help operate the app.
      </p>
      <p>
        <strong>Legal Compliance:</strong> If required by law, we may disclose
        information to authorities.
      </p>
      <p>
        <strong>Business Transfers:</strong> In case of a merger, acquisition,
        or sale of assets, your information may be transferred.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement security measures to protect your data. However, no method
        of transmission over the internet is 100% secure. We cannot guarantee
        absolute security.
      </p>

      <h2>6. Third-Party Links & Services</h2>
      <p>
        Our App may contain links to third-party websites or services. We are
        not responsible for their privacy practices. Please review their
        policies separately.
      </p>

      <h2>7. Your Rights & Choices</h2>

      <p>
        You may update or delete your personal information within the app
        settings.
      </p>
      <p>You can opt out of receiving promotional messages.</p>
      <p>You may revoke location access in your device settings.</p>

      <h2>8. Children's Privacy</h2>
      <p>
        Our App is not intended for children under the age of 13. We do not
        knowingly collect data from children. If you believe we have, please
        contact us to remove it.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any significant changes through the App or other communication
        methods.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns regarding this Privacy Policy,
        please contact us at: emailtomanuscripts@gmail.com
      </p>

      <p>
        By using our App, you agree to the terms outlined in this Privacy
        Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
