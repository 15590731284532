import React, { useState } from "react";
import styles from "./YourOrders.module.css";
import { useNavigate } from "react-router-dom";

import { AiOutlineDoubleRight } from "react-icons/ai";
import { BiDownload } from "react-icons/bi";
import { Link } from "react-router-dom";
import ReviewPop from "./ReviewPop";
import { useReviews } from "../../store/ReviewContext";

function YourOrderItemsData({ item }) {
  const navigate = useNavigate();
  const { reviews } = useReviews();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  function addReviewHandler() {
    setModalIsVisible(true);
  }
  function closeReviewHandler() {
    setModalIsVisible(false);
  }

  return (
    <div className={styles.card}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <img src={item.product_img_uri} alt={item.title} />
        <div className={styles.content}>
          <b>{item.product_name}</b>
          <p>
            Brand:
            {item.product_brand}
          </p>
          <p>
            Type:
            {item.product_quality}
          </p>
          <p>Price: {item.product_price}</p>
        </div>
        <div className={styles.lastbutton}>
          <div style={{ margin: "10px" }}>
            <Link
              className={styles.link}
              onClick={() => navigate(`/productsOverview/${item.product_id}`)}
            >
              Buy It Again
            </Link>
            <AiOutlineDoubleRight size={12} color="#121214" />
          </div>

          <div style={{ margin: "10px" }}>
            <Link className={styles.link}>Download Invoice</Link>
            <BiDownload size={20} color="#121214s" />
          </div>
          {reviews[item.product_id] ? (
            <div style={{ margin: "10px" }}>
              <Link className={styles.link}>Already reviewed this product</Link>
            </div>
          ) : (
            <div style={{ margin: "10px" }} onClick={addReviewHandler}>
              <Link className={styles.link}>Write a Product Review</Link>
            </div>
          )}

          {modalIsVisible && (
            <ReviewPop
              selectedItem={item}
              visible={modalIsVisible}
              onCancel={closeReviewHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default YourOrderItemsData;
