import React from "react";

function BathroomCleaning() {
  return (
    <div>
      <h2>Bathroom Cleaning</h2>
    </div>
  );
}

export default BathroomCleaning;
