import React from "react";

function WeeklyCleaning() {
  return (
    <div>
      <h2>Weekly Cleaning</h2>
    </div>
  );
}

export default WeeklyCleaning;
