import React, { useEffect, useState } from "react";
import styles from "../home/brands/Brands.module.css";
import useAxios from "../../hooks/useAxios";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { useAuthContext } from "../../store/AuthContext";
import Loader from "react-js-loader";
import CustomTitle from "../ui/CustomTitle";

const BuyItAgain = () => {
  const { token, userId } = useAuthContext();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();
  const skipCart = true;
  useEffect(() => {
    const transformTasks = (tasksObj) => {
      setTasks(tasksObj);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/Order/user/${userId}/${skipCart}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }
  function selectListItemHandler(item) {
    navigate(`/productsOverview/${item.product_id}`, { state: item });
  }
  return (
    <div className="luxury">
      <CustomTitle>Buy Again</CustomTitle>
      <div className={styles.slider_container}>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={6}
          navigation
          breakpoints={{
            // when window width is >= 340px
            340: {
              width: 200,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 4,
            },
            // when window width is >= 1040px
            1040: {
              width: 1040,
              slidesPerView: 5,
            },
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          /*  onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")} */
        >
          {tasks.map((item) => (
            <SwiperSlide key={item.product_id}>
              <div
                className={styles.card}
                onClick={() => selectListItemHandler(item)}
              >
                <img src={item.product_img_uri} alt="/" />
                <p>{item.product_name}</p>
                <p>Price:{item.product_price}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BuyItAgain;
