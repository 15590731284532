import { useEffect } from "react";
import { useState } from "react";
import "./Carousel.css";


const data = [
  {
    id: "c1",
    image:
      "https://images.pexels.com/photos/12924578/pexels-photo-12924578.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    id: "c7",
    image:
      "https://cdn3.vectorstock.com/i/1000x1000/65/57/concept-electrical-service-horizontal-banner-vector-26896557.jpg",
  },
  {
    id: "c6",
    image:
      "https://image.shutterstock.com/image-vector/banner-plumbing-service-landing-page-260nw-1980462008.jpg",
  },
  {
    id: "c7",
    image:
      "https://media.istockphoto.com/photos/repair-tools-on-a-white-background-banner-for-a-hardware-store-and-a-picture-id1085159358?k=20&m=1085159358&s=170667a&w=0&h=nCAm9AtJ0tAEQvB5a3buC6QYsemxLrNmxhi3Sz9OD8w=",
  },
  {
    id: "c4",
    image:
      "https://cdn-media.buildersmart.in/media/blog/Building-Materials.jpg",
  },
   
];

function Carousel() {
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 3000);
  });

  const slideRight = () => {
    setCurrent(current === data.length - 1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? data.length - 1 : current - 1);
  };
  //console.log(current);
  return (
    <div
      className="carousel"
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timeOut);
      }}
      onMouseLeave={() => {
        setAutoPlay(true);
      }}
    >
      <div className="carousel_wrapper">
        {data.map((image, index) => {
          return (
            /* (condition) ? true : false */

            <div
              key={index}
              className={
                index === current
                  ? "carousel_card carousel_card-active"
                  : "carousel_card"
              }
            >
              <img className="card_image" src={image.image} alt="" />
              <div className="card_overlay">
                {/* <h2 className="card_title">{image.title}</h2> */}
              </div>
            </div>
          );
        })}
        <div className="carousel_arrow_left" onClick={slideLeft}>
          &lsaquo;
        </div>
        <div className="carousel_arrow_right" onClick={slideRight}>
          &rsaquo;
        </div>
        <div className="carousel_pagination">
          {data.map((_, index) => {
            return (
              <div
                key={index}
                className={
                  index === current
                    ? "pagination_dot pagination_dot-active"
                    : "pagination_dot"
                }
                onClick={() => setCurrent(index)}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
