import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState();
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();

  const storeTokens = async ({ accessToken, refreshToken }) => {
    try {
      await localStorage.setItem("accessToken", accessToken);
      await localStorage.setItem("refreshToken", refreshToken);
    } catch (error) {
      console.error("Error storing tokens:", error);
    }
  };

  const getTokens = async () => {
    try {
      const accessToken = await localStorage.getItem("accessToken");
      const refreshToken = await localStorage.getItem("refreshToken");

      return { accessToken, refreshToken };
    } catch (error) {
      console.error("Error getting tokens:", error);
      return {};
    }
  };

  const api = axios.create({
    baseURL: "https://mason-api1.azurewebsites.net/api",
    /*  baseURL: " https://mason-api1.azurewebsites.net/api",*/
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Request interceptor to add the access token to the request headers
  api.interceptors.request.use(
    (config) => {
      const { accessToken } = getTokens();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // Response interceptor to handle token expiration and refresh
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const { refreshToken } = await getTokens();
        if (!refreshToken) {
          await logout();
          return Promise.reject(error);
        }

        try {
          const refreshResponse = await api.post("/refresh-token", {
            refreshToken,
          });
          const { accessToken: newAccessToken } = refreshResponse.data;
          await storeTokens(newAccessToken, refreshToken);

          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return api(originalRequest);
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          await logout();
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  const login = async (credentials) => {
    // console.log(credentials);
    try {
      let { username, password } = credentials;
      const response = await api.post("/login", {
        username: username,
        password: password,
      });
      // console.log(response);
      if (response.status === 200) {
        const { accessToken, refreshToken } = response.data;

        // Check if tokens are valid before storing them
        if (!accessToken || !refreshToken) {
          console.error("Invalid tokens received:", response.data);
          return;
        }

        await storeTokens({ accessToken, refreshToken });
        setAuthToken(accessToken);
        const user = jwtDecode(accessToken);
        const userData = user.username;
        const userId = user.id;
        setUser(userData);
        setUserId(userId);
      } else {
        // console.error("Login failed");
        alert(
          "Authentication failed!",
          "Could not log you in. Please check your credentials or try again later!"
        );
      }
    } catch (error) {
      //console.error("Error during login:", error);
      alert("Authentication failed!", "Error during login!");
    }
  };

  const signup = async (credentials) => {
    // console.log(credentials);
    try {
      let {
        firstName,
        lastName,
        username,
        mobile,
        password,
        email,
        dob,
        userRole,
      } = credentials;
      const response = await api.post("/Users", {
        user_firstname: firstName,
        user_lastname: lastName,
        user_contact_number: mobile,
        user_email: email,
        user_role: userRole,
        username: username,
        user_dob: dob,
        password: password,
      });

      if (response.status === 201) {
        //await login(username, password);
        //console.error("Signup Success");
        alert("Signup Success!", "Welcome");
      } else {
        // console.error("Signup failed");
        alert("Signup failed!", "Could not Register!");
      }
    } catch (error) {
      //console.error("Error during signup:", error);
      alert("Signup failed!", "Error during signup!");
    }
  };
  const Update = async (credentials) => {
    //console.log(credentials);
    try {
      let { user_id, firstName, lastName, username, mobile, email, dob } =
        credentials;
      const response = await api.put(`/Users/${user_id}`, {
        user_firstname: firstName,
        user_lastname: lastName,
        user_contact_number: mobile,
        user_email: email,
        username: username,
        user_dob: dob,
      });

      if (response.status === 200) {
        //console.error("Updated Successfully");
        alert("Update Success!");
      } else {
        // console.error("Signup failed", error);
        alert("Update failed!", "Could not Register!");
      }
    } catch (error) {
      // console.error("Error during signup:", error);
      alert("Update failed!", "Error during Update!");
    }
  };
  const logout = async () => {
    try {
      await localStorage.removeItem("accessToken");
      await localStorage.removeItem("refreshToken");
      setUser(null);
      setUserId(null);
      setAuthToken(null);
    } catch (error) {
      // console.error("Error during logout:", error);
      alert("Logout failed!", "Error during logout!");
    }
  };

  const checkTokenExpiration = async () => {
    const { accessToken, refreshToken } = await getTokens();

    //console.log("Access Token:", accessToken);
    //  console.log("Refresh Token:", refreshToken);

    if (accessToken) {
      try {
        // console.log("Decoding Access Token...");
        const decodedToken = jwtDecode(accessToken);
        // console.log("Decoded Token:", decodedToken);

        if (decodedToken.exp * 1000 < Date.now()) {
          //  console.log("Token expired, refreshing...");
          const refreshResponse = await api.post("/refresh-token", {
            refreshToken,
          });

          const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
            refreshResponse.data;
          await storeTokens({
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
          });

          const newDecodedToken = jwtDecode(newAccessToken);
          setUser(newDecodedToken.username);
          setUserId(newDecodedToken.id);
          setAuthToken(newAccessToken);
        } else {
          setUser(decodedToken.username);
          setUserId(decodedToken.id);
          setAuthToken(accessToken);
        }
      } catch (decodeError) {
        console.error("Error decoding token:", decodeError);
        await logout();
      }
    }
  };
  useEffect(() => {
    checkTokenExpiration();
  }, []);
  const value = useMemo(
    () => ({
      user,
      userId,
      token: authToken,
      isAuthenticated: !!authToken,
      login,
      signup,
      Update,
      logout,
    }),
    [user, userId, authToken, login, logout, signup, Update]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
