import React, { useState, useEffect, useCallback } from "react";
import { useAuthContext } from "../../store/AuthContext";
import YourOrdersData from "./YourOrdersData";
import EmptyOrders from "./EmptyOrders";
import Button from "../../components/ui/Button";
import useAxios from "../../hooks/useAxios";
import Loader from "react-js-loader";

function YourOrders() {
  const { token, userId } = useAuthContext();

  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  const fetchPaginatedTasks = useCallback(async (pageNumber = 1) => {
    const transformTasks = (tasksObj) => {
      if (!tasksObj || !tasksObj.data) {
        console.error("Invalid API response", tasksObj);
        return;
      }

      const loadedTasks = tasksObj.data.map((task) => ({
        orderId: task.order_id,
        sub_total: task.sub_total,
        order_item_id: task.order_item_id,
        total: task.total,
        grand_total: task.grand_total,
        quantity: task.quantity,
        product_id: task.product_id,
        imageUrl: task.product_img_uri,
        name: task.product_name,
      }));

      setTasks((prevTasks) => [...prevTasks, ...loadedTasks]);

      setTotalPages(tasksObj.totalPages || 1);
      setTotalRecords(tasksObj.totalRecords || 0);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/Order/${userId}?page=${pageNumber}&pageSize=3`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      transformTasks
    );
  }, []);

  useEffect(() => {
    fetchPaginatedTasks(page);
  }, [fetchPaginatedTasks, page]);

  const loadMoreData = () => {
    if (page < totalPages && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // console.log(tasks); // Debugging tasks

  if (isLoading && tasks.length === 0) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      {tasks.length === 0 && <EmptyOrders />}
      {tasks.length !== 0 && <YourOrdersData data={tasks} />}
      <div style={{ width: "10%", padding: "auto", margin: "auto" }}>
        {page < totalPages && (
          <Button onClick={loadMoreData}>Load More...</Button>
        )}
      </div>
    </div>
  );
}

export default YourOrders;
