import React, { useState } from "react";
import Modal from "../../ui/Modal";
import Delivery from "../../../images/delivery.png";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../../ui/Button";
import { useNavigate } from "react-router-dom";
import styles from "./LocationPop.module.css";
import WhiteButton from "../../ui/WhiteButton";
import { useAuthContext } from "../../../store/AuthContext";
import { useAddressList } from "../../../store/AddressContext";

const fetchPincode = async (
  latitude,
  longitude,
  setPincode,
  setCity,
  props
) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();
    const pincode = data.address?.postcode || "Pincode not found";
    const city = data?.address?.city || "city not found";
    setPincode(pincode);
    setCity(city);
    props.selectedPinCode(pincode);
    props.selectedCity(city);
    props.onCancel();
  } catch (error) {
    console.error("Error fetching pincode:", error);
    setPincode("Error fetching pincode");
  }
};

function LocationPop(props) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const { address } = useAddressList();
  const [selectedAddress, setSelectedAdress] = useState(address[0]);
  const [location, setLocation] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [city, setCity] = useState(null);
  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      setPincode("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
        fetchPincode(latitude, longitude, setPincode, setCity, props);
      },
      (error) => {
        console.error("Error getting location:", error);
        setPincode("Error getting location");
      }
    );
  };

  function AddAddressHandler() {
    props.onCancel();
    navigate("/profile/AddressBook");
  }
  function SelectedAdressHandler(item) {
    setSelectedAdress(item);
    props.selectedAddress(item);
    props.onCancel();
  }
  return (
    <Modal visible={props.visible} onCancel={props.onCancel}>
      <div
        style={{ float: "right", cursor: "pointer" }}
        onClick={props.onCancel}
      >
        <AiOutlineClose size={25} />
      </div>
      <div className={styles.modalContent}>
        <div className={styles.deliveryImg}>
          <img src={Delivery} alt="Delivery" />
        </div>
        <div>
          <h1>Where Would You Like us to deliver?</h1>
        </div>
        <div className={styles.mainContainer}>
          {isAuthenticated && (
            <div>
              {address?.map((item, index) => (
                <div
                  key={index}
                  className={
                    selectedAddress === item
                      ? styles.addressSelectContainer
                      : styles.addressContainer
                  }
                  onClick={() => SelectedAdressHandler(item)}
                >
                  <p>
                    <b>
                      {item.first_name} {item.last_name}
                    </b>
                  </p>
                  <p>
                    {item.address},{item.pincode}
                  </p>
                  <p>
                    {item.city},{item.country}
                  </p>
                  <p>
                    {item.pincode},{item.mobile}
                  </p>
                  {item.default_address && (
                    <p>
                      <b>Default Address</b>
                    </p>
                  )}
                </div>
              ))}
              <div
                className={styles.addressContainer}
                onClick={AddAddressHandler}
              >
                <p> Add an Address or pick-up point</p>
              </div>
            </div>
          )}
        </div>
        <div>
          <form>
            <div className="control-group">
              <div className="form-control">
                <input
                  type="number"
                  placeholder="Enter Your Pincode or Area or City"
                  min="0"
                  step="1"
                />
                <div
                  style={{
                    width: "50%",
                    padding: "auto",
                    margin: "auto",
                    marginTop: "20px",
                  }}
                >
                  <Button type="submit">Submit</Button>
                </div>
              </div>
              <div style={{ width: "50%", padding: "auto", margin: "auto" }}>
                <WhiteButton type="submit" onClick={getCurrentLocation}>
                  Choose Current Location
                </WhiteButton>
              </div>
              {/* {pincode && (
                <div style={{ marginTop: "10px" }}>
                  <p>
                    <strong>Pincode:</strong> {pincode}
                  </p>
                </div>
              )} */}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default LocationPop;
