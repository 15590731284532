import React, { useEffect, useState } from "react";
import styles from "./Categories.module.css";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import Loader from "react-js-loader";

const SubCategories = () => {
  const params = useParams();
  const navigate = useNavigate();
  const catId = params.id;
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          categoryId: tasksObj[taskKey].category_1_id,
          id: tasksObj[taskKey].category_2_id,
          name: tasksObj[taskKey].category_2_name,
          url: tasksObj[taskKey].category_2_img_uri,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/Category/${catId}?categoryLevel=2`,
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  if (tasks.length === "0") {
    return <p>No Data Found </p>;
  }
  return (
    <div className={styles.luxury}>
      <div className={styles.heading}></div>
      <div className={styles.container}>
        {tasks.map((item) => (
          <div className={styles.card} key={item.id}>
            <img
              src={item.url}
              alt={item.title}
              onClick={() => navigate(`/productsdata/${item.id}`)}
            />
            <div className={styles.content}>
              <h3>{item.name}</h3>
            </div>
          </div>
        ))}{" "}
      </div>
    </div>
  );
};

export default SubCategories;
