class SubService {
  constructor(id, catId, image, title, name) {
    this.id = id;
    this.catId = catId;
    this.image = image;
    this.title = title;
    this.name = name;
  }
}

export default SubService;
