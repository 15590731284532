import React from "react";

function Subscriptions() {
  return (
    <div>
      <h2>Subscriptions</h2>
    </div>
  );
}

export default Subscriptions;
