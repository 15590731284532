import React, { useState, useEffect } from "react";
import styles from "./ProductsPage.module.css";
import useAxios from "../../hooks/useAxios";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useParams, useNavigate } from "react-router-dom";
import BrandsMenu from "../../components/sidebar/brandsmenu/BrandsMenu";
import EmptyFilter from "../../components/sidebar/filters/EmptyFilter";
import BrandsListView from "../../components/home/brands/BrandsListView";
import Loader from "react-js-loader";
function BrandsListPage() {
  const params = useParams();
  const navigate = useNavigate();
  const brandId = params.id;
  const [nav, setNav] = useState(false);

  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          brand_id: tasksObj[taskKey].brand_id,
          brand_img_uri: tasksObj[taskKey].brand_img_uri,
          title: tasksObj[taskKey].title,
          category_2_id: tasksObj[taskKey].category_2_id,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: "https://mason-api1.azurewebsites.net/api/brands",
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  const displayedBrandsList = tasks?.filter((listItem) => {
    return listItem.category_2_id === brandId;
  });

  return (
    <div className={styles.article}>
      <div className={styles.menuBlock}>
        <div
          className={
            nav ? [styles.menu, styles.active].join(" ") : [styles.menu]
          }
        >
          <BrandsMenu />
        </div>
      </div>
      <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
        {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
      </div>

      {displayedBrandsList.length != 0 ? (
        <BrandsListView item={displayedBrandsList} />
      ) : (
        <div>
          <EmptyFilter />
        </div>
      )}
    </div>
  );
}

export default BrandsListPage;
