import React from "react";

function EducationFees() {
  return (
    <div>
      <h2>Education Fees</h2>
    </div>
  );
}

export default EducationFees;
