class OrderList {
  constructor(
    id,
    productId,
    title,
    imageUrl,
    paidOn,
    deliveredOn,
    TotalAmount,
    MSmiles,
    MCash,
    savings,
    brand 
  ) {
    this.id = id;
    this.productId = productId;
    this.title = title;  
    this.imageUrl = imageUrl;
    this.paidOn = paidOn;
    this.deliveredOn = deliveredOn;  
    this.TotalAmount = TotalAmount;
    this.MSmiles = MSmiles;
    this.MCash =MCash;
    this.savings = savings;
    this.brand = brand   
  }
}

export default OrderList;
