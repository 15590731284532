import React from "react";

function PremiumCleaning() {
  return (
    <div>
      <h2>Premium Cleaning</h2>
    </div>
  );
}

export default PremiumCleaning;
