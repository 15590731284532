class Address {
  constructor(address_id,user_id, first_name,last_name,middle_name, mobile,email,address,pincode,city,state,country) {
   this.address_id = address_id;
    this.user_id = user_id;
     this.first_name = first_name;
     this. last_name = last_name;
    this.middle_name = middle_name;
      this.mobile = mobile;
     this.email = email;
      this.address = address;
      this. pincode =pincode;
     this.city = city;
     this.state = state;
      this.country =country;
   
  }
}

export default Address;
