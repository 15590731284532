import React from "react";

function PippedGas() {
  return (
    <div>
      <h2>Pipped Gas</h2>
    </div>
  );
}

export default PippedGas;
