import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import { useAuthContext } from "../../store/AuthContext";
import EmptyBuyAgain from "./EmptyBuyAgain";
import BuyAgainData from "./BuyAgainData";
import Loader from "react-js-loader";

function BuyAgain() {
  const skipCart = false;
  const { token, userId } = useAuthContext();
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      setTasks(tasksObj);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/Order/user/${userId}/${skipCart}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      {tasks.length === 0 && <EmptyBuyAgain />}
      {tasks.length !== 0 && <BuyAgainData list={tasks} />}
    </div>
  );
}

export default BuyAgain;
