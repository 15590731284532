import React, { useState, useEffect } from "react";
import ReactStars from "react-stars";
import MultiRangeSlider from "multi-range-slider-react";
import Button from "../../ui/Button";
import styles from "./Filters.module.css";
import useAxios from "../../../hooks/useAxios";
import Loader from "react-js-loader";
import CustomTitle from "../../ui/CustomTitle";

const Filters = (props) => {
  const [catId, setCatId] = useState(props.catId);
  const [brandCheckedBox, setBrandCheckedBox] = useState([]);
  const [typeCheckedBox, setTypeCheckedBox] = useState([]);
  const [rating, setRating] = useState(0);
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(1000);

  const [tasksCategory, setTasksCategory] = useState([]);
  const [tasksBrand, setTasksBrand] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedCategoryTasks = [];
      const loadedBrandTasks = [];
      const categories = tasksObj.categories;
      const brands = tasksObj.brands;

      for (const taskKey in categories) {
        loadedCategoryTasks.push({
          id: categories[taskKey].category_3_id,
          name: categories[taskKey].category_3_name,
        });
      }
      setTasksCategory(loadedCategoryTasks);

      for (const taskKey in brands) {
        loadedBrandTasks.push({
          id: brands[taskKey].brand_id,
          name: brands[taskKey].title,
        });
      }
      setTasksBrand(loadedBrandTasks);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/productFilters/${catId}`,
      },
      transformTasks
    );
  }, []);

  /*  const taskAddHandler = (task) => {
    setTasks((prevTasks) => prevTasks.concat(task));
  }; */

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  const PriceHandleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  function BrandhandleChange(e) {
    if (e.target.checked) {
      setBrandCheckedBox([...brandCheckedBox, e.target.value]);
    } else {
      setBrandCheckedBox(
        brandCheckedBox.filter((item) => item !== e.target.value)
      );
    }
  }

  function TypehandleChange(e) {
    if (e.target.checked) {
      setTypeCheckedBox([...typeCheckedBox, e.target.value]);
    } else {
      setTypeCheckedBox(
        typeCheckedBox.filter((item) => item !== e.target.value)
      );
    }
  }

  function ratingChanged(newRating) {
    setRating(newRating);
  }

  const ShowResultHandler = () => {
    const filteredData = {
      rating: rating,
      minValue: minValue,
      maxValue: maxValue,
      brandsCheckedBox: brandCheckedBox,
      typesCheckedBox: typeCheckedBox,
    };
    //console.log(filteredData);
    props.onSubmit(filteredData);
  };

  const HideResultHandler = () => {
    setBrandCheckedBox([]);
    setTypeCheckedBox([]);
    setRating("");
    setMinValue(1);
    setMaxValue(1000);
    props.onCancel();
  };
  return (
    <div>
      <CustomTitle>Filters</CustomTitle>
      <div className={styles.content}>
        <div className={styles.heading}>
          <h3>Brands</h3>
        </div>

        {tasksBrand.map((item) => (
          <div key={item.id}>
            <input
              value={item.name}
              type="checkbox"
              onChange={BrandhandleChange}
              checked={brandCheckedBox.includes(item.name)}
            />
            <span> {item.name} </span>
          </div>
        ))}
      </div>

      <div className={styles.content}>
        <div className={styles.heading}>
          <h3>Types</h3>
        </div>
        {tasksCategory.map((item) => (
          <div key={item.id}>
            <input
              value={item.name}
              type="checkbox"
              onChange={TypehandleChange}
              checked={typeCheckedBox.includes(item.name)}
            />
            <span> {item.name} </span>
          </div>
        ))}
      </div>

      <div className={styles.content}>
        <div className={styles.heading}>
          <h3>Price Range</h3>
        </div>
        <MultiRangeSlider
          min={1}
          max={1000}
          step={5}
          minValue={minValue}
          maxValue={maxValue}
          style={{
            border: "none",
            boxShadow: "none",
            padding: "15px 10px",
            width: "80%",
          }}
          label="false"
          ruler="false"
          onInput={(e) => {
            PriceHandleInput(e);
          }}
        />
      </div>
      <div>
        {minValue},{maxValue}
      </div>

      <div className={styles.content}>
        <div className={styles.heading}>
          <h3>Rating</h3>
        </div>
        <ReactStars
          count={5}
          onChange={ratingChanged}
          size={34}
          color2={"#ffd700"}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "40%", marginRight: "10px" }}>
          <Button type="submit" onClick={ShowResultHandler}>
            Apply Filters
          </Button>
        </div>
        <div style={{ width: "40%" }}>
          <Button type="submit" onClick={HideResultHandler}>
            Clear Filters
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
