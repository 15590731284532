import React from "react";
import styles from "./wishlist.module.css";
import Button from "../../components/ui/Button";
import { useNavigate, Link } from "react-router-dom";
import { useCartList } from "../../store/CartContext";
import { useWishlist } from "../../store/WishlistContext";
import { MdDelete } from "react-icons/md";
import { useReviews } from "../../store/ReviewContext";
import ReactStars from "react-stars";
import CustomTitle from "../../components/ui/CustomTitle";

function WishListData({ list }) {
  const navigate = useNavigate();
  const { addToCartItem } = useCartList();
  const { removeWishlistProduct } = useWishlist();
  const { reviews } = useReviews();

  function AddItemHandler(list) {
    //console.log(list);
    const cartNumber = 1;
    addToCartItem({ list, cartNumber });
    removeWishlistProduct(list.product_id);
  }

  const toggleWishlist = (item) => {
    //console.log(item);
    removeWishlistProduct(item);
  };
  const selectListItemHandler = (item) => {
    //console.log(item);
    navigate(`/productsOverview/${item.product_id}`, { state: item });
  };
  return (
    <div className={styles.luxury}>
      <CustomTitle>Your WhishList</CustomTitle>
      <div className={styles.container}>
        {list.map((item) => (
          <div className={styles.card} key={item.product_id}>
            <div onClick={() => selectListItemHandler(item)}>
              <img src={item.product_img_uri} alt={item.product_name} />
            </div>
            <div className={styles.content}>
              <h3>{item.product_name}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <ReactStars
                  count={5}
                  value={Number(reviews[list.product_id]?.average_rating) || 0}
                  size={25}
                  color2={"#ffd700"}
                />
                <div
                  style={{
                    margin: "5px",
                  }}
                >
                  <p>{reviews[list.product_id]?.average_rating || 0}/5</p>
                </div>
              </div>
              <p>
                <b>Brand: </b>
                {item.product_brand}
              </p>
              <p>
                <b>Type: </b>
                {item.product_quality}
              </p>
              <p>
                <b>Price:</b> {item.product_price}
              </p>
              {/*  <p>{item.description}</p> 
              <p>{item.description.substr(0, 100) + "..."}</p>*/}
            </div>
            <div className={styles.ButtonRow}>
              <Button type="Submit" onClick={() => AddItemHandler(item)}>
                Move to Cart
              </Button>
              <Link onClick={() => toggleWishlist(item.product_id)}>
                <MdDelete size={26} color="#000000" style={{ marginTop: 5 }} />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WishListData;
