import React from "react";
import EmptyCartImg from "../../images/empytcart.png";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import CardBackground from "../ui/CardBackground";

const EmptyCart = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={EmptyCartImg}
          alt="Empty Cart"
          style={{ width: "50%", height: "50%" }}
        />
        <h4>Your Cart is Empty</h4>
        <p>Looks like you have not added anything to your cart.</p>
        <p>Go ahead & explore top Brands</p>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/category/:id")}>
            Go to Store
          </Button>
        </div>
      </CardBackground>
    </div>
  );
};

export default EmptyCart;
