class YourBills {
  constructor(id, title, image, number) {
    this.id = id;
    this.title = title;
    this.image = image;
    this.number = number;
  }
}

export default YourBills;
