import React from "react";

function HomeCleaning() {
  return (
    <div>
      <h2>Home Cleaning</h2>
    </div>
  );
}

export default HomeCleaning;
