import React from "react";
import styles from "./CustomlabourServices.module.css";

import { useNavigate } from "react-router-dom";

const CustomlabourServices = ({ list }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.luxury}>
      <div className={styles.card}>
        {list.map((item) => (
          <div
            key={item.id}
            className={styles.content}
            onClick={() => navigate(`/services/${item.name}`)}
          >
            <img src={item.image} alt={item.name} />
            <div>
              <p>{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomlabourServices;
