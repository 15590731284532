import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import styles from "./Categories.module.css";
import { useNavigate } from "react-router-dom";
import CustomTitle from "../../ui/CustomTitle";
import Loader from "react-js-loader";

function Categories() {
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();
  const navigate = useNavigate();
  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          id: tasksObj[taskKey].category_1_id,
          name: tasksObj[taskKey].category_1_name,
          url: tasksObj[taskKey].category_1_img_uri,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: "https://mason-api1.azurewebsites.net/api/Category?categoryLevel=1",
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.luxury}>
      <CustomTitle>Explore the Marketplace</CustomTitle>

      <div className={styles.container}>
        {tasks?.map((item) => (
          <div
            className={styles.card}
            key={item.id}
            onClick={() => navigate(`/subCategories/${item.id}`)}
          >
            <img src={item.url} alt={item.name} />
            <div className={styles.content}>
              <h3>{item.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Categories;
