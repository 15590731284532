import React, { useState } from "react";
import ReactStars from "react-stars";
import MultiRangeSlider from "multi-range-slider-react";
import Button from "../../ui/Button";
import styles from "./Filters.module.css";

const BrandFilters = (props) => {
  const [rating, setRating] = useState(0);
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(1000);

  const PriceHandleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  function ratingChanged(newRating) {
    setRating(newRating);
  }

  const ShowResultHandler = () => {
    const filteredData = {
      rating: rating,
      minValue: minValue,
      maxValue: maxValue,
    };
    //console.log(filteredData);
    props.onSubmit(filteredData);
  };

  const HideResultHandler = () => {
    setRating("");
    setMinValue(1);
    setMaxValue(1000);
    props.onCancel();
  };
  return (
    <div>
      <div className={styles.heading}>
        <h3>Filters</h3>
      </div>

      <div className={styles.content}>
        <div className={styles.heading}>
          <h3>Price Range</h3>
        </div>
        <MultiRangeSlider
          min={1}
          max={1000}
          step={5}
          minValue={minValue}
          maxValue={maxValue}
          style={{
            border: "none",
            boxShadow: "none",
            padding: "15px 10px",
            width: "80%",
          }}
          label="false"
          ruler="false"
          onInput={(e) => {
            PriceHandleInput(e);
          }}
        />
      </div>
      <div>
        {minValue},{maxValue}
      </div>

      <div className={styles.content}>
        <div className={styles.heading}>
          <h3>Rating</h3>
        </div>
        <ReactStars
          count={5}
          onChange={ratingChanged}
          size={34}
          color2={"#ffd700"}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "40%", marginRight: "10px" }}>
          <Button type="submit" onClick={ShowResultHandler}>
            Apply Filters
          </Button>
        </div>
        <div style={{ width: "40%" }}>
          <Button type="submit" onClick={HideResultHandler}>
            Clear Filters
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BrandFilters;
