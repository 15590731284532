import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import appleAppStore from "../../../images/appleAppStore.png";
import googlePlay from "../../../images/googlePlay.png";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div>
          <p>Download Mason Mobile App Now!!!</p>
          <img
            src={appleAppStore}
            alt="appleAppStore"
            className={styles.logoImage}
          />
          <img src={googlePlay} alt="googlePlay" className={styles.logoImage} />
        </div>
        <div style={{ margin: 15 }}>
          <ul>
            <li>
              <Link to={"/aboutus"}>About Us</Link>
            </li>
            <li>
              <Link to={"/contact"}>Contact Us</Link>
            </li>
            <li>
              <Link to={"/terms"}>Terms & Conditions</Link>
            </li>
          </ul>
        </div>
        <div style={{ margin: 15 }}>
          <ul>
            <li>
              <Link to={"/category/:id"}>Store</Link>
            </li>
            <li>
              <Link to={"/privacyPolicy"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/faq"}>Customer Support</Link>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              <Link to={"/"}>+9000000000</Link>
            </li>
            <li>
              <Link to={"/"}>emailtomanuscripts@gmail.com</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
