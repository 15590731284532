import React from 'react'
import Register from '../../components/login/Register';

function RegistrationPage() {
  return (
    <div>         
          <Register/>
    </div>
  );
}

export default RegistrationPage;
