import React from "react";
import notfound from "../../images/notfound.png";
import CardBackground from "../ui/CardBackground";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";

const Emptypage = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={notfound}
          alt="notfound"
          style={{ width: "10%", height: "10%" }}
        />
        <div>
          <h3>Page Not Found</h3>
          <p> We cannot find the page that you're looking for.</p>
          <p>Please check the address and try again</p>
          <p>or please login and try again</p>
        </div>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/")}>
            Go to Home
          </Button>
        </div>
      </CardBackground>
    </div>
  );
};

export default Emptypage;
