import React, { useState } from "react";
import styles from "./DeliveryOptions.module.css";
import CardBackground from "../../components/ui/CardBackground";
import Button from "../../components/ui/Button";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import CustomTitle from "../../components/ui/CustomTitle";

const deliverySlots = [
  {
    id: "0",
    date: "Tommorow By",
    timeSlot: "4PM - 8PM",
  },
  {
    id: "1",
    date: "Today By",
    timeSlot: "4PM - 8PM",
  },
];
const DeliverySlots = ({ nextStep, prevStep, selectedAddress }) => {
  const [deliveryAddress, setDeliveryAddress] = useState(selectedAddress);
  const [selectedSlot, setSelectedSlot] = useState("");
  function SlotHandler(item) {
    setSelectedSlot(item);
  }

  function nextStepHandler() {
    if (selectedSlot) {
      nextStep();
    } else {
      alert("please select one Slot");
      //console.log("please select one Address");
    }
  }
  return (
    <div className={styles.luxury}>
      <CardBackground>
        <div>
          <p>
            <b> Delivering to {deliveryAddress.first_name}</b>
          </p>
          <p>
            {deliveryAddress.address},{deliveryAddress.city},
            {deliveryAddress.state}
            <br />
            {deliveryAddress.country},{deliveryAddress.pincode},
            {deliveryAddress.mobile}
          </p>
        </div>
      </CardBackground>

      <CustomTitle>Select the Slot for Delivery</CustomTitle>
      <div className={styles.container}>
        {deliverySlots?.map((item, index) => (
          <CardBackground key={index}>
            <div className={styles.content}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {selectedSlot.id === item?.id ? (
                  <IoIosRadioButtonOn size={20} color="#121214" />
                ) : (
                  <IoIosRadioButtonOff
                    onClick={() => SlotHandler(item)}
                    size={20}
                    color="gray"
                  />
                )}

                <h3> {item.date}</h3>
                <p>
                  <b> {item.timeSlot}</b>
                </p>
              </div>
            </div>
          </CardBackground>
        ))}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Button type="submit" onClick={prevStep}>
              Previous
            </Button>
          </div>
          <div>
            <Button type="submit" onClick={nextStepHandler}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliverySlots;
