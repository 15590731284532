import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";
import axios from "axios";

const API_URL = "https://mason-api1.azurewebsites.net/api"; // replace with your backend URL

const CartContext = createContext();

export const useCartList = () => useContext(CartContext);

export const CartContextProvider = ({ children }) => {
  const { token, userId } = useAuthContext();

  const [cart, setCart] = useState([]);

  useEffect(() => {
    const getCartlist = async () => {
      try {
        const response = await axios.get(`${API_URL}/cart/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const cartItems = response.data;
        setCart(cartItems);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    if (userId) {
      getCartlist();
    }
  }, [userId, token]);
  //console.log(cart);

  const addToCartItem = async ({ list, cartNumber }) => {
    //console.log(list, cartNumber);

    try {
      const ItemPresent = cart.find(
        (item) => item.product_id === list.product_id
      );

      const method = ItemPresent ? "put" : "post";
      const url = `${API_URL}/cart`; // Include product_id in URL if updating

      const response = await axios({
        method: method,
        url: url,
        data: {
          product_id: list.product_id,
          quantity: cartNumber,
          user_id: userId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedCartItem = await response.data;

      if (ItemPresent) {
        setCart(
          cart.map((x) =>
            x.product_id === list.product_id
              ? { ...ItemPresent, quantity: cartNumber }
              : x
          )
        );
      } else {
        setCart([
          ...cart,
          {
            ...updatedCartItem,
            quantity: cartNumber,
          },
        ]);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const deleteCartItem = async (productId) => {
    try {
      const response = await axios.delete(`${API_URL}/cart`, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is set
        },
        data: {
          userId, // Assuming you're sending userId and productId in the request body
          productId,
        },
      });

      setCart(cart.filter((x) => x.product_id !== productId));
    } catch (error) {
      console.error("Error deleting item from cart:", error);
    }
  };
  const deleteAllCartItem = async () => {
    try {
      const response = await axios.delete(`${API_URL}/cart/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is set
        },
      });
    } catch (error) {
      console.error("Error deleting item from cart:", error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        addToCartItem,
        deleteCartItem,
        deleteAllCartItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
