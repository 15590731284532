import React from "react";
import styles from "./ServicesHomePage.module.css";
import { SERVICES } from "../../data/dummy-data";
import { SUBSERVICES } from "../../data/dummy-data";
import { useNavigate } from "react-router-dom";
import CustomTitle from "../../components/ui/CustomTitle";

function ServicesHomePage() {
  const navigate = useNavigate();

  const renderChildList = (parent) => {
    const filteredChildren = SUBSERVICES.filter(
      (child) => child.catId === parent.id
    );
    if (filteredChildren.length === 0) return null;
    return (
      <div className={styles.card}>
        {filteredChildren.map((item) => (
          <div
            key={item.id}
            className={styles.content}
            onClick={() => navigate(`/services/${item.name}`)}
          >
            <img src={item.image} alt={item.name} />
            <div>
              <p>{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.luxury}>
      <CustomTitle>Explore the Services</CustomTitle>
      <div className={styles.card}>
        {SERVICES.map((item) => (
          <div
            key={item.id}
            className={styles.content}
            onClick={() => navigate(`/services/${item.name}`)}
          >
            <img src={item.image} alt={item.title} />
            <div>
              <p>{item.title}</p>
            </div>
          </div>
        ))}
      </div>
      {SERVICES.map((parent) => {
        const childList = renderChildList(parent);
        return childList ? (
          <div key={parent.id}>
            <div className={styles.heading}>
              <h3>{parent.title}</h3>
            </div>
            {childList}
          </div>
        ) : null;
      })}
    </div>
  );
}

export default ServicesHomePage;
