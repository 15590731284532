import React from "react";
import styles from "./Categories.module.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlus, AiFillCreditCard } from "react-icons/ai";
import CardBackground from "../../components/ui/CardBackground";
import CustomTitle from "../../components/ui/CustomTitle";

const deliveryAddress = [
  {
    id: "1",
    name: "Customer Name1",
    flat: "Apartment Number and Name1",
    area: "Area Name1",
    city: "City1",
    district: "District1",
    state: "Stat1e",
    country: "Country1",
    pincode: "Pin Code1",
    mobile: "Mobile1",
  },
  {
    id: "2",
    name: "Customer Name2",
    flat: "Apartment Number and Name2",
    area: "Area Name2",
    city: "City2",
    district: "District2",
    state: "State2",
    country: "Country2",
    pincode: "Pin Code2",
    mobile: "Mobile2",
  },
  {
    id: "3",
    name: "Customer Name2",
    flat: "Apartment Number and Name2",
    area: "Area Name2",
    city: "City2",
    district: "District2",
    state: "State2",
    country: "Country2",
    pincode: "Pin Code2",
    mobile: "Mobile2",
  },
];
function ManagePayments() {
  const navigate = useNavigate();
  return (
    <div className={styles.luxury}>
      <CustomTitle>Your Payment Options</CustomTitle>
      <CardBackground>
        <h4>Your default purchase preference</h4>
        <Link>Change Preference</Link>

        <p>
          <b>ADDRESS</b>
        </p>
        <p>
          Prasad, 201,Sai Lakshmi residency, Indiranagar,Gandhi Statue lane,
        </p>
        <p>chandanagar, HYDERABAD, TELANGANA, 500050</p>
        <p>
          <b>Payment Method</b>
        </p>
        <p>
          <AiFillCreditCard size={24} color="#000" /> ICICI Bank Credit Card
          ending in 3003
        </p>
      </CardBackground>
      <div>
        <h4>Your saved credit and debit cards</h4>
      </div>
      <div className={styles.container}>
        <div className={styles.card} onClick={() => navigate("/newAddress")}>
          <div className={styles.content}>
            <p>
              <b>Add New Card Details</b>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AiOutlinePlus size={60} color="#121214" />
          </div>
        </div>
        {deliveryAddress.map((item) => (
          <div className={styles.card} key={item.id}>
            <div className={styles.content}>
              <p style={{ marginBottom: "10px" }}>
                {" "}
                <AiFillCreditCard size={24} color="#000" /> ICICI Bank Credit
                Card ending in 3003
              </p>
              <p style={{ marginBottom: "10px" }}>
                <b>Billing Address</b>
              </p>
              <p>{item.name} </p>
              <p>
                {item.flat},{item.area}
              </p>
              <p>
                {item.city},{item.district}
              </p>
              <p>
                {" "}
                {item.state},{item.country}
              </p>
              <p>
                {item.pincode},{item.mobile}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to={"/"} className={styles.link}>
                Edit
              </Link>
              |
              <Link to={"/"} className={styles.link}>
                Remove
              </Link>
            </div>
          </div>
        ))}{" "}
      </div>
    </div>
  );
}

export default ManagePayments;
