import React, { useState, useEffect } from "react";
import styles from "./BrandsMenu.module.css";

import { useNavigate } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import useAxios from "../../../hooks/useAxios";
import Loader from "react-js-loader";

const BrandsAccordianMenu = ({ subCat }) => {
  const [selected, setSelected] = useState(null);

  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          id: tasksObj[taskKey].category_1_id,
          name: tasksObj[taskKey].category_1_name,
          url: tasksObj[taskKey].category_1_img_uri,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: "https://mason-api1.azurewebsites.net/api/Category?categoryLevel=1",
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  const toggle = (id) => {
    if (selected === id) {
      return setSelected(null);
    }
    setSelected(id);
  };

  const displayedList = subCat.filter((listItem) => {
    return listItem.categoryId === selected;
  });

  return (
    <div className={styles.luxury}>
      <div>
        {tasks.map((item) => (
          <div
            className={styles.card}
            key={item.id}
            onClick={() => toggle(item.id)}
          >
            <div className={styles.content}>
              <h3>{item.name}</h3>
              <div className={styles.arrow}>
                {selected === item.id ? (
                  <AiFillCaretUp size={20} color="#302d2d" />
                ) : (
                  <AiFillCaretDown size={20} color="#302d2d" />
                )}
              </div>
            </div>
            {selected === item.id && (
              <div className={styles.luxury}>
                <div className={styles.container}>
                  {displayedList.map((item) => (
                    <div className={styles.card1} key={item.id}>
                      <img
                        src={item.url}
                        alt={item.name}
                        onClick={() => navigate(`/brandsList/${item.id}`)}
                      />
                      <div className={styles.content1}>
                        <h3>{item.name}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandsAccordianMenu;
