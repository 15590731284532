import React from "react";
import styles from "../../../pages/home/ProductsPage.module.css";
import { useNavigate } from "react-router-dom";

const BrandsListView = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.luxury}>
      <div className={styles.container}>
        {item.map((item) => (
          <div
            className={styles.card}
            key={item.brand_id}
            onClick={() => navigate(`/brandsOverview/${item.brand_id}`)}
          >
            <img src={item.brand_img_uri} alt={item.title} />
            <div className={styles.content}>
              <h3>{item.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandsListView;
