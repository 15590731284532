import React, { useState } from "react";
import styles from "./Products.module.css";
import OverViewCart from "./OverViewCart";
import useInput from "../../../hooks/use-input";
import Button from "../../ui/Button";
import { useCartList } from "../../../store/CartContext";
import { useNavigate, Link } from "react-router-dom";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { useWishlist } from "../../../store/WishlistContext";
import { useAuthContext } from "../../../store/AuthContext";
import ReactStars from "react-stars";
import { useReviews } from "../../../store/ReviewContext";
import ReviewPop from "../../../pages/profile/ReviewPop";
import WhiteButton from "../../ui/WhiteButton";
import AlsoOrdered from "../../cart/AlsoOrdered";
import CardBackground from "../../ui/CardBackground";
import CustomTitle from "../../ui/CustomTitle";

const isNotEmpty = (value) => value.trim() !== "";

const ProductsOverViewData = ({ list }) => {
  const { cart, addToCartItem, deleteCartItem } = useCartList();
  const { wishlist, addWishlistProduct, removeWishlistProduct } = useWishlist();
  const { userId } = useAuthContext();
  const { reviews } = useReviews();
  const navigate = useNavigate();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const isInWishlist = wishlist.some(
    (item) => item.product_id === list.product_id
  );

  const {
    value: cartNumber,
    isValid: cartNumberIsValid,
    hasError: cartNumberHasError,
    valueChangeHandler: CartChangeHandler,
    inputBlurHandler: cartBlurHandler,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (cartNumberIsValid) {
    formIsValid = true;
  }

  const ItemPresent = cart.find((item) => item.product_id === list.product_id);

  function submitHandler(event) {
    event.preventDefault();
    if (userId) {
      if (!formIsValid) {
        return;
      } else {
        addToCartItem({ list, cartNumber });
        removeWishlistProduct(list.product_id);
        navigate(`/addtoCart`, { state: list });
      }
    } else {
      navigate("/auth");
    }
  }
  const toggleWishlist = (item) => {
    if (userId) {
      //console.log(userId, item);
      if (isInWishlist) {
        removeWishlistProduct(item);
      } else {
        addWishlistProduct(item);
        deleteCartItem(item);
      }
    } else {
      navigate("/auth");
    }
  };
  const cartNumberClasses = cartNumberHasError
    ? "form-control invalid"
    : "form-control";

  const addReviewHandler = () => {
    if (userId) {
      //console.log("done");
      setModalIsVisible(true);
    } else {
      navigate("/auth");
    }
  };
  function closeReviewHandler() {
    setModalIsVisible(false);
  }
  return (
    <>
      <div className="luxury">
        <div className={styles.card1} key={list.product_id}>
          <img
            src={list.product_img_uri}
            alt={list.product_name}
            className={styles.img}
          />
          <div className={styles.content}>
            <div className={styles.rowContent}>
              <h3>{list.product_name}</h3>
              <div onClick={() => toggleWishlist(list.product_id)}>
                {isInWishlist ? (
                  <MdFavorite
                    size={22}
                    color="#CE180B"
                    style={{ marginTop: 15, cursor: "pointer" }}
                  />
                ) : (
                  <MdFavoriteBorder
                    size={22}
                    color="#CE180B"
                    style={{ marginTop: 15, cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <ReactStars
                count={5}
                value={Number(reviews[list.product_id]?.average_rating) || 0}
                size={25}
                color2={"#ffd700"}
              />
              <div
                style={{
                  marginLeft: "5px",
                  fontSize: "10px",
                }}
              >
                <p>{reviews[list.product_id]?.average_rating || 0}/5</p>
              </div>
            </div>
            <p>
              <b>Brand: </b>
              {list.product_brand}
            </p>
            <p>
              <b>Type: </b>
              {list.product_quality}
            </p>
            <p>
              <b>Price:</b> {list.product_price}
            </p>

            {ItemPresent ? (
              <OverViewCart list={list} />
            ) : (
              <form onSubmit={submitHandler}>
                <div className="control-group-row">
                  <div className={cartNumberClasses}>
                    <input
                      type="number"
                      placeholder="0"
                      min="0"
                      step="1"
                      onChange={CartChangeHandler}
                      value={cartNumber}
                      onBlur={cartBlurHandler}
                    />
                    {cartNumberHasError && (
                      <p className="error-text">Please Enter the Quantity.</p>
                    )}
                  </div>
                  <div style={{ width: "40%", marginTop: "10px" }}>
                    <Button type="submit" disabled={!formIsValid}>
                      Add to Cart
                    </Button>
                  </div>
                </div>
              </form>
            )}
            <div>
              {reviews[list.product_id] ? null : (
                <div style={{ margin: "10px" }}>
                  <WhiteButton type="submit" onClick={addReviewHandler}>
                    Write a Product Review
                  </WhiteButton>
                </div>
              )}

              {modalIsVisible && (
                <ReviewPop
                  selectedItem={list}
                  visible={modalIsVisible}
                  onCancel={closeReviewHandler}
                />
              )}
            </div>
          </div>
        </div>
        {userId ? (
          <div style={{ width: "100%" }}>
            <AlsoOrdered />
          </div>
        ) : null}
        <div className={styles.comments}>
          {reviews[list.product_id]?.comments.length > 0 ? (
            <div>
              <CustomTitle>customers Reviews</CustomTitle>
              <div>
                <CardBackground>
                  {reviews[list.product_id].comments.map((item, index) => (
                    <div key={index} className={styles.divider}>
                      <p>
                        <b>
                          {item.user_firstname} {item.user_lastname}
                        </b>
                      </p>
                      <p>{item.comment}</p>
                    </div>
                  ))}
                </CardBackground>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <h3>customers Reviews</h3>
              </div>

              <p>No comments available.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsOverViewData;
