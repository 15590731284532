import React from "react";
import CardBackground from "../../components/ui/CardBackground";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import NoAddress from "../../images/noaddress.png";

const EmptyAdresses = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={NoAddress}
          alt="NoAddress"
          style={{ width: "15%", height: "15%" }}
        />
        <div>
          <h4>No Address Yet</h4>
          <p> Please add your address for your better experience</p>
        </div>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/newAddress")}>
            Add Address
          </Button>
        </div>
      </CardBackground>
    </div>
  );
};

export default EmptyAdresses;
