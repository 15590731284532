import React from "react";
import NoProductImg from "../../../images/no-products.jpg";
import CardBackground from "../../ui/CardBackground";

const EmptyFilter = () => {
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={NoProductImg}
          alt="Empty Cart"
          style={{ width: "50%", height: "50%" }}
        />
      </CardBackground>
    </div>
  );
};

export default EmptyFilter;
