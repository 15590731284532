import { useState, useCallback } from "react";
import axios from "axios";

const useAxios = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async (config, applyData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios({
        method: config.method || "GET",
        url: config.url,
        data: config.data || null,
        headers: config.headers || {},
      });

      applyData(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, error, sendRequest };
};

export default useAxios;
