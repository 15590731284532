import React from "react";
import { SUBSERVICES } from "../../../data/dummy-data";
import CustomlabourServices from "../../../components/services/CustomlabourServices";

function ACService() {
  const displayedList = SUBSERVICES.filter((listItem) => {
    return listItem.catId == 6;
  });

  return (
    <div>
      <CustomlabourServices list={displayedList} />
    </div>
  );
}

export default ACService;
