import React from "react";
import useInput from "../../../hooks/use-input";
import CardBackground from "../../../components/ui/CardBackground";
import Button from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const isNotEmpty = (value) => value.trim() !== "";

function PayRent() {
  const navigate = useNavigate();
  const {
    value: paymentValue,
    isValid: paymentIsValid,
    hasError: paymentHasError,
    valueChangeHandler: paymentChangeHandler,
    inputBlurHandler: paymentBlurHandler,
    reset: resetPayment,
  } = useInput(isNotEmpty);
  const {
    value: yourNameValue,
    isValid: yourNameIsValid,
    hasError: yourNameHasError,
    valueChangeHandler: yourNameChangeHandler,
    inputBlurHandler: yourNameBlurHandler,
    reset: resetYourName,
  } = useInput(isNotEmpty);

  const {
    value: mobileValue,
    isValid: mobileIsValid,
    hasError: mobileHasError,
    valueChangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    reset: resetMobile,
  } = useInput(isNotEmpty);
  const {
    value: accNumber,
    isValid: accNumberIsValid,
    hasError: accNumberHasError,
    valueChangeHandler: accNumberChangeHandler,
    inputBlurHandler: accNumberBlurHandler,
    reset: resetAccNumber,
  } = useInput(isNotEmpty);
  const {
    value: ConfirmAccNumber,
    isValid: ConfirmAccNumberIsValid,
    hasError: ConfirmAccNumberHasError,
    valueChangeHandler: ConfirmAccNumberChangeHandler,
    inputBlurHandler: ConfirmAccNumberBlurHandler,
    reset: resetConfirmaccNumber,
  } = useInput(isNotEmpty);
  const {
    value: ifscnumber,
    isValid: ifscnumberIsValid,
    hasError: ifscnumberHasError,
    valueChangeHandler: ifscnumberChangeHandler,
    inputBlurHandler: ifscnumberBlurHandler,
    reset: resetIfscnumber,
  } = useInput(isNotEmpty);
  const {
    value: bhkValue,
    isValid: bhkIsValid,
    hasError: bhkHasError,
    valueChangeHandler: bhkChangeHandler,
    inputBlurHandler: bhkBlurHandler,
    reset: resetBhk,
  } = useInput(isNotEmpty);
  const {
    value: addressValue,
    isValid: addressIsValid,
    hasError: addressHasError,
    valueChangeHandler: addressChangeHandler,
    inputBlurHandler: addressBlurHandler,
    reset: resetAddress,
  } = useInput(isNotEmpty);
  const {
    value: rentValue,
    isValid: rentIsValid,
    hasError: rentHasError,
    valueChangeHandler: rentChangeHandler,
    inputBlurHandler: rentBlurHandler,
    reset: resetRent,
  } = useInput(isNotEmpty);
  const {
    value: panValue,
    isValid: panIsValid,
    hasError: panHasError,
    valueChangeHandler: panChangeHandler,
    inputBlurHandler: panBlurHandler,
    reset: resetPan,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (
    mobileIsValid &&
    yourNameIsValid &&
    accNumberIsValid &&
    ConfirmAccNumberIsValid &&
    panIsValid &&
    rentIsValid &&
    addressIsValid &&
    ifscnumberIsValid
  ) {
    formIsValid = true;
  }

  async function submitHandler(event) {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }
    const credentials = {
      payment: paymentValue,
      yourname: yourNameValue,
      mobile: mobileValue,
      AccontNumber: accNumber,
      bhk: bhkValue,
      pan: panValue,
      rent: rentValue,
      address: addressValue,
      ifsc: ifscnumber,
    };
    console.log(credentials);

    resetMobile();
    resetYourName();
    resetAccNumber();
    resetAddress();
    resetConfirmaccNumber();
    resetIfscnumber();
    resetPan();
    resetRent();
  }

  const yourNameClasses = yourNameHasError
    ? "form-control invalid"
    : "form-control";
  const mobileClasses = mobileHasError
    ? "form-control invalid"
    : "form-control";
  const accNumberClasses = accNumberHasError
    ? "form-control invalid"
    : "form-control";
  const confirmaccNumberClasses = ConfirmAccNumberHasError
    ? "form-control invalid"
    : "form-control";
  const panClasses = panHasError ? "form-control invalid" : "form-control";
  const rentClasses = rentHasError ? "form-control invalid" : "form-control";
  const addressClasses = addressHasError
    ? "form-control invalid"
    : "form-control";
  const ifscClasses = ifscnumberHasError
    ? "form-control invalid"
    : "form-control";
  return (
    <div className="luxury" style={{ maxWidth: "800px" }}>
      <div className="heading">
        <h3>Your Details</h3>
        <p>Name,Email,Mobile</p>
      </div>
      <div className="heading">
        <h3>Payment Details</h3>
        <p> Fill Your Payment Details once and we will Save it</p>
      </div>

      <CardBackground>
        <form onSubmit={submitHandler}>
          <div className="control-group">
            <div className="form-control">
              <select
                placeholder="Payment Type"
                value={paymentValue} // ...force the select's value to match the state variable...
                onChange={paymentChangeHandler} // ... and update the state variable on any change!
              >
                <option value="payment">Payment Type</option>
                <option value="rent">House Rent</option>
                <option value="Fees">School/Collage fees</option>
                <option value="Tution">Tution Fees</option>
                <option value="Society">Society Maintenance</option>
                <option value="Office">Office/Shop Rent</option>
                <option value="Token">Property Token</option>
                <option value="Deposit">Property Deposit</option>
              </select>
              {paymentHasError && (
                <p className="error-text">Please Select the BHK.</p>
              )}
            </div>
            <div>
              <label> Landlord Name (As per bank records)</label>
            </div>
            <div className={yourNameClasses}>
              <input
                type="text"
                placeholder="Enter Name."
                value={yourNameValue}
                onChangeText={yourNameChangeHandler}
                onBlur={yourNameBlurHandler}
              />
              {yourNameHasError && (
                <p className="error-text">Please enter a Name.</p>
              )}
            </div>
            <div>
              <label>Landlord Number</label>
            </div>
            <div className={mobileClasses}>
              <input
                type="number"
                placeholder="Mobile Number."
                value={mobileValue}
                onChangeText={mobileChangeHandler}
                onBlur={mobileBlurHandler}
              />
              {mobileHasError && (
                <p className="error-text">Please enter a mobile number.</p>
              )}
            </div>
            <div>
              <label>Bank Account Number</label>
            </div>
            <div className={accNumberClasses}>
              <input
                type="number"
                placeholder="Enter Bank's Account Number."
                value={accNumber}
                onChangeText={accNumberChangeHandler}
                onBlur={accNumberBlurHandler}
              />
              {accNumberHasError && (
                <p className="error-text">Please enter your Account Number.</p>
              )}
            </div>
            <div>
              <label> Confirm Account Number</label>
            </div>
            <div className={confirmaccNumberClasses}>
              <input
                type="number"
                placeholder="Re-enter Bank's Account Number."
                value={ConfirmAccNumber}
                onChangeText={ConfirmAccNumberChangeHandler}
                onBlur={ConfirmAccNumberBlurHandler}
              />
              {ConfirmAccNumberHasError && (
                <p className="error-text">Please enter your Account Number.</p>
              )}
            </div>
            <div>
              <label>IFSC Code</label>
            </div>
            <div className={ifscClasses}>
              <input
                type="text"
                placeholder="eg. SBIN0000952."
                value={ifscnumber}
                onChangeText={ifscnumberChangeHandler}
                onBlur={ifscnumberBlurHandler}
              />
              {ifscnumberHasError && (
                <p className="error-text">Please enter a IFSC Code.</p>
              )}
            </div>
            <div>
              <label>BHK Type</label>
            </div>
            <div className="form-control">
              <select
                placeholder="BHK Type"
                value={bhkValue} // ...force the select's value to match the state variable...
                onChange={bhkChangeHandler} // ... and update the state variable on any change!
              >
                <option value="userRole">BHK Type</option>
                <option value="1RK">1RK</option>
                <option value="1BHK">1BHK</option>
                <option value="2BHK">2BHK</option>
                <option value="3BHK">3BHK</option>
                <option value="4BHK">4BHK</option>
                <option value="4+BHK">4+BHK</option>
              </select>
              {bhkHasError && (
                <p className="error-text">Please Select the BHK.</p>
              )}
            </div>
            <div>
              <label>Property Address</label>
            </div>
            <div className={addressClasses}>
              <input
                type="text"
                placeholder="Enter Address."
                value={addressValue}
                onChangeText={addressChangeHandler}
                onBlur={addressBlurHandler}
              />
              {addressHasError && (
                <p className="error-text">Please enter Address.</p>
              )}
            </div>
            <div>
              <label>Rent Amount</label>
            </div>
            <div className={rentClasses}>
              <input
                type="text"
                placeholder="Enter Amount."
                value={rentValue}
                onChangeText={rentChangeHandler}
                onBlur={rentBlurHandler}
              />
              {rentHasError && <p className="error-text">Please enter Rent.</p>}
            </div>
            <div>
              <label>Beneficiary's PAN</label>
            </div>
            <div className={panClasses}>
              <input
                type="text"
                placeholder="Enter Your Beneficiary's pan."
                value={panValue}
                onChangeText={panChangeHandler}
                onBlur={panBlurHandler}
              />
              {panHasError && (
                <p className="error-text">Please enter PAN Number.</p>
              )}
            </div>
          </div>

          <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
            <Button type="submit" disabled={!formIsValid}>
              Save Payment Details
            </Button>
          </div>
        </form>
      </CardBackground>
    </div>
  );
}

export default PayRent;
