import React from "react";

function AcInstallation() {
  return (
    <div>
      <h2>Ac Installation</h2>
    </div>
  );
}

export default AcInstallation;
