class Category {
  constructor(id, categoryIds, title, url) {
    this.id = id;
    this.categoryIds = categoryIds;
    this.title = title;
    this.url = url;
  }
}

export default Category;
