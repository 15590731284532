import React from "react";
import styles from "./HomePageServices.module.css";
import { SERVICES } from "../../data/dummy-data";
import { useNavigate } from "react-router-dom";
import more from "../../images/more.png";
import CardBackground from "../ui/CardBackground";
import CustomTitle from "../ui/CustomTitle";

const HomePageServices = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CustomTitle>Explore the Services</CustomTitle>
      <CardBackground>
        <div className={styles.card}>
          {SERVICES.slice(0, 4).map((item) => (
            <div key={item.id} className={styles.content}>
              <img src={item.image} alt={item.name} />
              <div>
                <p>{item.title}</p>
              </div>
            </div>
          ))}
          <div onClick={() => navigate("/services")}>
            <img src={more} alt="more" />
          </div>
        </div>
      </CardBackground>
    </div>
  );
};

export default HomePageServices;
