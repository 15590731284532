import { useNavigate } from "react-router-dom";
import useInput from "../../../hooks/use-input";
import Button from "../../ui/Button";
import { useCartList } from "../../../store/CartContext";
import { useWishlist } from "../../../store/WishlistContext";
const isNotEmpty = (value) => value.trim() !== "";

const OverViewCart = ({ list }) => {
  const { cart, addToCartItem } = useCartList();
  const { removeWishlistProduct } = useWishlist();
  const navigate = useNavigate();

  const {
    value: cartNumber,
    isValid: cartNumberIsValid,
    hasError: cartNumberHasError,
    valueChangeHandler: CartChangeHandler,
    inputBlurHandler: cartBlurHandler,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (cartNumberIsValid) {
    formIsValid = true;
  }

  function submitHandler(event) {
    event.preventDefault();

    if (!formIsValid) {
      return;
    } else {
      addToCartItem({ list, cartNumber });
      removeWishlistProduct(list.product_id);
      navigate(`/addtoCart`, { state: list });
    }
  }
  const cartNumberClasses = cartNumberHasError
    ? "form-control invalid"
    : "form-control";

  return (
    <>
      {cart.map((item) =>
        item.product_id === list.product_id ? (
          <form onSubmit={submitHandler} key={item.product_id}>
            <div className="control-group-row">
              <div className={cartNumberClasses}>
                <input
                  type="number"
                  defaultValue={item.quantity}
                  editable="true"
                  min="0"
                  step="1"
                  onChange={CartChangeHandler}
                  onBlur={cartBlurHandler}
                />
                {cartNumberHasError && (
                  <p className="error-text">Please Enter the Quantity.</p>
                )}
              </div>
              <div style={{ width: "40%", marginTop: "10px" }}>
                <Button type="submit" disabled={!formIsValid}>
                  Add to Cart
                </Button>
              </div>
            </div>
          </form>
        ) : null
      )}
    </>
  );
};

export default OverViewCart;
