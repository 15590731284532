import React from "react";
import Logo from "../../../images/logo.png";
import styles from "./Header.module.css";
function LogoImg() {
  return (
    <div>
      <img src={Logo} alt="Logo" className={styles.logoImg} />
    </div>
  );
}

export default LogoImg;
