import React from "react";
import styles from "./Categories.module.css";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { useAddressList } from "../../store/AddressContext";
import Button from "../../components/ui/Button";
import WhiteButton from "../../components/ui/WhiteButton";
import CustomTitle from "../../components/ui/CustomTitle";

function AddressbookData() {
  const navigate = useNavigate();
  const { address, deleteAddress } = useAddressList();
  const DeliveryFormHandler = (item) => {
    navigate("/newAddress", { state: { data: item } });
  };

  const DeleteFormHandler = (address_id) => {
    deleteAddress(address_id);
  };
  return (
    <div className={styles.luxury}>
      <CustomTitle>Your Addresses</CustomTitle>
      <div className={styles.container}>
        <div className={styles.card} onClick={() => navigate("/newAddress")}>
          <div className={styles.content}>
            <p>
              <b>Add New Address</b>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AiOutlinePlus size={60} color="#121214" />
          </div>
        </div>
        {address.map((item) => (
          <div className={styles.card} key={item.address_id}>
            <div className={styles.content}>
              <p>
                <b>
                  {item.first_name}
                  {item.last_name}
                </b>
              </p>
              <p>
                {item.address},{item.city}
              </p>

              <p>
                {item.state},{item.country}
              </p>
              <p>
                {item.pincode},{item.mobile}
              </p>
              {item.default_address && <h4>Default Address</h4>}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ marginRight: "10px" }}>
                <WhiteButton
                  type="submit"
                  onClick={() => DeliveryFormHandler(item)}
                >
                  Edit
                </WhiteButton>
              </div>
              <div style={{ marginRight: "10px" }}>
                <WhiteButton
                  type="submit"
                  onClick={() => DeleteFormHandler(item?.address_id)}
                >
                  Remove
                </WhiteButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddressbookData;
