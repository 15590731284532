import React from "react";

function Insurance() {
  return (
    <div>
      <h2>Insurance</h2>
    </div>
  );
}

export default Insurance;
