import React from "react";
import CardBackground from "../../components/ui/CardBackground";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import NoPayments from "../../images/nopayments.png";

const EmptyPayments = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={NoPayments}
          alt="Empty Cart"
          style={{ width: "20%", height: "20%" }}
        />
        <div>
          <h4>Save your Credit/Debit Cards</h4>
          <p>Its Convenient to pay with saved cards.</p>
          <p> Your Card information is secured with us</p>
        </div>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/newAddress")}>
            Add Address
          </Button>
        </div>
      </CardBackground>
    </div>
  );
};

export default EmptyPayments;
