import React from "react";
import styles from "./CustomTitle.module.css";
const CustomTitle = (props) => {
  return (
    <div className={styles.textcontainer}>
      <h2 className={styles.title}>{props.children}</h2>
      <div className={styles.underline}></div>
      <div className={styles.underlinethin}></div>
    </div>
  );
};

export default CustomTitle;
