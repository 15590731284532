import React from "react";
import styles from "./Products.module.css";
import ReactStars from "react-stars";

import { useNavigate } from "react-router-dom";
import { useReviews } from "../../../store/ReviewContext";

const Products = ({ data }) => {
  const navigate = useNavigate();
  const { reviews } = useReviews();

  // console.log(data);
  return (
    <div className="luxury">
      <div>
        {data.map((item, index) => (
          <div
            key={index}
            className={styles.card}
            onClick={() =>
              navigate(`/productsOverview/${item.product_id}`, { state: item })
            }
          >
            <img src={item.product_img_uri} alt={item.product_name} />
            <div className={styles.content}>
              <h3>{item.product_name}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <ReactStars
                  count={5}
                  value={Number(reviews[item.product_id]?.average_rating) || 0} // Convert to number
                  size={20}
                  color2={"#ffd700"}
                />
                <div
                  style={{
                    marginLeft: "5px",
                    fontSize: "10px",
                  }}
                >
                  <p>{reviews[item.product_id]?.average_rating || 0}/5</p>
                </div>
              </div>
              <p>
                <b>Brand: </b>
                {item.product_brand}
              </p>
              <p>
                <b>Type: </b>
                {item.product_quality}
              </p>
              <p>
                <b>Price:</b> {item.product_price}/{item.price_unit}
              </p>
              {/*  <p>{item.description}</p> 
                <p>{item.description.substr(0, 100) + "..."}</p>*/}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
