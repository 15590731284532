import React from "react";
import styles from "./profile.module.css";

function Msmile() {
  return (
    <div className={styles.luxury}>
      <div className={styles.heading}>
        <h1>Msmile</h1>
      </div>
    </div>
  );
}

export default Msmile;
