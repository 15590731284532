import useInput from "../../hooks/use-input";
import styles from "./CartData.module.css";
import Button from "../ui/Button";
import WhiteButton from "../ui/WhiteButton";
import { useCartList } from "../../store/CartContext";
import { useWishlist } from "../../store/WishlistContext";
const isNotEmpty = (value) => value.trim() !== "";

const CartData = ({ list }) => {
  const { addToCartItem, deleteCartItem } = useCartList();
  const { addWishlistProduct } = useWishlist();
  const {
    value: cartNumber,
    isValid: cartNumberIsValid,
    hasError: cartNumberHasError,
    valueChangeHandler: CartChangeHandler,
    inputBlurHandler: cartBlurHandler,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (cartNumberIsValid) {
    formIsValid = true;
  }

  function submitHandler(event) {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }
    if (cartNumber === "0" || cartNumber === "") {
      deleteCartItem(list.product_id);
    } else {
      addToCartItem({ list, cartNumber });
    }
  }
  const deleteCartItems = (id) => {
    // console.log(id);
    deleteCartItem(id);
  };
  const SaveWishlistItem = (id) => {
    // console.log(id);
    addWishlistProduct(id);
    deleteCartItem(id);
  };
  const cartNumberClasses = cartNumberHasError
    ? "form-control invalid"
    : "form-control";
  // console.log(cart);
  return (
    <div className="luxury">
      <div className={styles.card}>
        <img src={list.product_img_uri} alt={list.product_name} />
        <div className={styles.content}>
          <h3>{list.product_name}</h3>
          <p>
            <b>Brand: </b>
            {list.product_brand}
          </p>
          <p>
            <b>Type: </b>
            {list.product_quality}
          </p>
          <p>
            <b>Price:</b> ₹{list.product_price}
          </p>

          <form onSubmit={submitHandler}>
            <div className="control-group-row" key={list.product_id}>
              <div className={cartNumberClasses}>
                <input
                  type="number"
                  defaultValue={list.quantity}
                  editable="true"
                  min="0"
                  step="1"
                  onChange={CartChangeHandler}
                  onBlur={cartBlurHandler}
                />
                {cartNumberHasError && (
                  <p className="error-text">Please Enter the Quantity.</p>
                )}
              </div>
              <div style={{ width: "40%", marginTop: "10px" }}>
                <Button type="submit" disabled={!formIsValid}>
                  Add to Cart
                </Button>
              </div>
            </div>
          </form>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <WhiteButton
                type="submit"
                onClick={() => deleteCartItems(list.product_id)}
              >
                Delete
              </WhiteButton>
            </div>
            <div>
              <WhiteButton
                type="submit"
                onClick={() => SaveWishlistItem(list.product_id)}
              >
                Save for Later
              </WhiteButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartData;
