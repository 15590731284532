import React from "react";
import styles from "./DeliverySlot.module.css";
import { AiOutlineClockCircle } from "react-icons/ai";
import Colors from "../../ui/Colors";

function DeliverySlot() {
  return (
    <div className={styles.slot}>
      <h1>
        Earliest <b style={{ color: Colors.primary600 }}>Home Delivery</b> is
        available
      </h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <AiOutlineClockCircle size={18} color={Colors.primary400} />
        <p>
          <b>Tomorrow 07:00 AM - 09:30AM</b>
        </p>
      </div>
    </div>
  );
}

export default DeliverySlot;
