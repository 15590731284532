import { useEffect } from "react";
import useInput from "../../hooks/use-input";
import CardBackground from "../ui/CardBackground";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../store/AuthContext";
import { useLocation } from "react-router-dom";
import CustomTitle from "../ui/CustomTitle";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");
const passwordLength = (value) => value.length > 6;

const Register = () => {
  const navigate = useNavigate();
  const { signup, userId, Update } = useAuthContext();
  const location = useLocation();
  const initialValues = location.state?.data;

  useEffect(() => {
    if (initialValues && userId) {
      const [updatedInitialValues] = initialValues;

      setFirstNameValue(updatedInitialValues.user_firstname);
      setLastNameValue(updatedInitialValues.user_lastname);
      setDobValue(updatedInitialValues.user_dob);
      setMobileValue(updatedInitialValues.user_contact_number);
      setEmailValue(updatedInitialValues.user_email);
      setUserNameValue(updatedInitialValues.username);
    }
  }, [initialValues]);

  const {
    value: firstNameValue,
    setvalue: setFirstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(isNotEmpty);
  const {
    value: lastNameValue,
    setvalue: setLastNameValue,
    isValid: lastNameIsValid,
    valueChangeHandler: lastNameChangeHandler,
    reset: resetLastName,
  } = useInput(isNotEmpty);
  const {
    value: dobValue,
    setvalue: setDobValue,
    isValid: dobIsValid,
    hasError: dobHasError,
    valueChangeHandler: dobChangeHandler,
    inputBlurHandler: dobBlurHandler,
    reset: resetDob,
  } = useInput(isNotEmpty);
  const {
    value: mobileValue,
    setvalue: setMobileValue,
    isValid: mobileIsValid,
    hasError: mobileHasError,
    valueChangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    reset: resetMobile,
  } = useInput(isNotEmpty);
  const {
    value: emailValue,
    setvalue: setEmailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);
  const {
    value: userNameValue,
    setvalue: setUserNameValue,
    isValid: userNameIsValid,
    hasError: userNameHasError,
    valueChangeHandler: userNameChangeHandler,
    inputBlurHandler: userNameBlurHandler,
    reset: resetUserName,
  } = useInput(isNotEmpty);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(passwordLength);
  const {
    value: confirmPasswordValue,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    reset: resetConfirmPassword,
  } = useInput(passwordLength);
  const {
    value: userRoleValue,
    isValid: userRoleIsValid,
    hasError: userRoleHasError,
    valueChangeHandler: userRoleChangeHandler,
    reset: resetUserRole,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (
    firstNameIsValid &&
    lastNameIsValid &&
    dobIsValid &&
    mobileIsValid &&
    emailIsValid &&
    userNameIsValid &&
    passwordIsValid &&
    confirmPasswordIsValid &&
    userRoleIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    const passwordsAreEqual = passwordValue === confirmPasswordValue;
    if (!passwordsAreEqual) {
      alert("Password and Confirm Password are not same");
    } else {
      if (!formIsValid) {
        return;
      }

      //console.log("Submitted!");

      const credentials = {
        firstName: firstNameValue,
        lastName: lastNameValue,
        dob: dobValue,
        username: userNameValue,
        mobile: mobileValue,
        password: passwordValue,
        email: emailValue,
        userRole: userRoleValue,
      };
      signup(credentials);
      navigate("/auth");
      resetFirstName();
      resetLastName();
      resetDob();
      resetMobile();
      resetEmail();
      resetUserName();
      resetPassword();
      resetConfirmPassword();
      resetUserRole();
    }
  };
  function UpdateHandler(event) {
    event.preventDefault();
    //console.log("updated");

    const credentials = {
      user_id: userId,
      firstName: firstNameValue,
      lastName: lastNameValue,
      dob: dobValue,
      email: emailValue,
      username: userNameValue,
      mobile: mobileValue,
    };
    // console.log(credentials);
    Update(credentials);
    navigate("/profile");
    resetFirstName();
    resetLastName();
    resetUserName();
    resetMobile();
    resetDob();
  }
  const firstNameClasses = firstNameHasError
    ? "form-control invalid"
    : "form-control";
  const passwordClasses = passwordHasError
    ? "form-control invalid"
    : "form-control";
  const mobileClasses = mobileHasError
    ? "form-control invalid"
    : "form-control";
  const emailClasses = emailHasError ? "form-control invalid" : "form-control";
  const userNameClasses = userNameHasError
    ? "form-control invalid"
    : "form-control";
  const confirmPasswordClasses = confirmPasswordHasError
    ? "form-control invalid"
    : "form-control";
  const dobClasses = dobHasError ? "form-control invalid" : "form-control";
  //const userRoleClasses = userRoleClasses ? 'form-control invalid' : 'form-control';

  return (
    <div className="luxury" style={{ maxWidth: "800px" }}>
      <CustomTitle>{userId ? "Your Profile" : "Register"}</CustomTitle>
      <CardBackground>
        <form>
          <div className="control-group">
            <div className={firstNameClasses}>
              <input
                type="text"
                placeholder="First Name."
                value={firstNameValue}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
              />
              {firstNameHasError && (
                <p className="error-text">Please enter a Name.</p>
              )}
            </div>
            <div className="form-control">
              <input
                type="text"
                placeholder="Last Name."
                value={lastNameValue}
                onChange={lastNameChangeHandler}
              />
            </div>
            <div className={dobClasses}>
              <input
                type="date"
                placeholder="Date of Birth"
                value={dobValue}
                onChange={dobChangeHandler}
                onBlur={dobBlurHandler}
              />
              {dobHasError && (
                <p className="error-text">Please enter a Date of birth.</p>
              )}
            </div>
            <div className={mobileClasses}>
              <input
                type="number"
                placeholder="Mobile Number."
                value={mobileValue}
                onChange={mobileChangeHandler}
                onBlur={mobileBlurHandler}
              />
              {mobileHasError && (
                <p className="error-text">Please enter a Mobile Number.</p>
              )}
            </div>
            <div className={emailClasses}>
              <input
                type="text"
                placeholder="Email."
                value={emailValue}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
              />
              {emailHasError && (
                <p className="error-text">Please enter a Email.</p>
              )}
            </div>

            <div className={userNameClasses}>
              <input
                type="text"
                placeholder="User Name."
                value={userNameValue}
                onChange={userNameChangeHandler}
                onBlur={userNameBlurHandler}
              />
              {userNameHasError && (
                <p className="error-text">Please enter a User Name.</p>
              )}
            </div>
            {!userId && (
              <div className={passwordClasses}>
                <input
                  type="password"
                  placeholder="Password"
                  value={passwordValue}
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                />
                {passwordHasError && (
                  <p className="error-text">Please enter a password.</p>
                )}
              </div>
            )}

            {!userId && (
              <div className={confirmPasswordClasses}>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPasswordValue}
                  onChange={confirmPasswordChangeHandler}
                  onBlur={confirmPasswordBlurHandler}
                />
                {confirmPasswordHasError && (
                  <p className="error-text">Please enter a password.</p>
                )}
              </div>
            )}

            {!userId && (
              <div className="form-control">
                <select
                  placeholder="Select User Role"
                  value={userRoleValue} // ...force the select's value to match the state variable...
                  onChange={userRoleChangeHandler} // ... and update the state variable on any change!
                >
                  <option value="userRole">User Role</option>
                  <option value="admin">Admin</option>
                  <option value="mason">Mason</option>
                  <option value="user">User</option>
                </select>
                {userRoleHasError && (
                  <p className="error-text">Please Select the User Role.</p>
                )}
              </div>
            )}
          </div>
          {!userId ? (
            <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
              <Button
                type="submit"
                disabled={!formIsValid}
                onClick={submitHandler}
              >
                Register
              </Button>
            </div>
          ) : (
            <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
              <Button type="submit" onClick={UpdateHandler}>
                Update
              </Button>
            </div>
          )}
          {userId && (
            <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
              <Button type="submit" onClick={() => navigate("/profile")}>
                Cancel
              </Button>
            </div>
          )}
        </form>
      </CardBackground>
    </div>
  );
};

export default Register;
