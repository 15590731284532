class Categorieslist {
  constructor(
    id,
    categoryIds,
    title,
    rating,
    brand,
    type,
    imageUrl,
    price,
    description

  ) {
    this.id = id;
    this.categoryIds = categoryIds;
    this.title = title;
    this.rating = rating;
    this.brand = brand;
    this.type = type;
    this.imageUrl = imageUrl;
    this.price = price;
    this.description = description;
  }
}

export default Categorieslist;
