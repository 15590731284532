import React from "react";
import styles from "./DeliveryOptions.module.css";
import CardBackground from "../../components/ui/CardBackground";
import Button from "../../components/ui/Button";

const Payment = ({ nextStep, prevStep }) => {
  return (
    <div className={styles.luxury}>
      <div className={styles.heading}>
        <h3>Select the Payment Options</h3>
      </div>
      <div className={styles.container}>
        <CardBackground>
          <div className={styles.content}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input type="radio" tyle={{ marginRight: 30 }} />

              <p>
                <b>Cash On Delivery</b>
              </p>
            </div>
          </div>
        </CardBackground>
        <CardBackground>
          <div className={styles.content}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input type="radio" tyle={{ marginRight: 30 }} />

              <p>
                <b>UPI / Credit or debit card</b>
              </p>
            </div>
          </div>
        </CardBackground>
        <CardBackground>
          <div className={styles.content}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input type="radio" tyle={{ marginRight: 30 }} />

              <p>
                <b>Net Banking</b>
              </p>
            </div>
          </div>
        </CardBackground>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Button type="submit" onClick={prevStep}>
              Previous
            </Button>
          </div>
          <div>
            <Button type="submit" onClick={nextStep}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
