import React from "react";

function SofaCleaning() {
  return (
    <div>
      <h2>Sofa Cleaning</h2>
    </div>
  );
}

export default SofaCleaning;
