import React from "react";

function AcGas() {
  return (
    <div>
      <h2>Ac Gas Filling</h2>
    </div>
  );
}

export default AcGas;
