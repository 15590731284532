import React from "react";
import { SERVICES } from "../../data/dummy-data";
import { SUBSERVICES } from "../../data/dummy-data";

function Deals() {
  const renderParent = (parent) => {
    return (
      <div key={parent.id} className="parentContainer">
        <p className="parentText">{parent.title}</p>
      </div>
    );
  };

  const renderChild = (child) => {
    return (
      <div key={child.id} className="childContainer">
        <p className="childText">{child.title}</p>
      </div>
    );
  };

  const renderChildList = (parentId) => {
    const filteredChildren = SUBSERVICES.filter(
      (child) => child.catId === parentId
    );
    if (filteredChildren.length === 0) return null;
    return <div>{filteredChildren.map(renderChild)}</div>;
  };

  return (
    <div className="container">
      <h2 className="header">Parents</h2>
      {SERVICES.map(renderParent)}

      {SERVICES.map((parent) => {
        const childList = renderChildList(parent.id);
        return childList ? (
          <div key={parent.id}>
            <h3>{parent.name}</h3>
            {childList}
          </div>
        ) : null;
      })}
      {/* 
      {SERVICES.map((parent) => (
        <div key={parent.id}>
          <h3 className="header">{parent.title}</h3>
          {renderChildList(parent.id)}
        </div>
      ))} */}
    </div>
  );
}

export default Deals;
