import React from 'react'
import styles from './Header.module.css';

import LogoImg from './LogoImg';
//import Search from './Search';
import HeaderIcons from './HeaderIcons';
import LocationSearch from './LocationSearch';
//import DeliverySlot from './DeliverySlot';

function Header() {
  return (
    <div>
     <header className={styles.navbar}>
    <LogoImg/>
   {/*  <Search/> 
   <DeliverySlot/>*/}
    <LocationSearch/>
    <HeaderIcons/>     
      
    </header>
    </div>
  );
}

export default Header;
