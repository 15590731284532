import React from "react";
import styles from "./profile.module.css";

function Savings() {
  return (
    <div className={styles.luxury}>
      <div className={styles.heading}>
        <h1>Savings</h1>
      </div>
    </div>
  );
}

export default Savings;
