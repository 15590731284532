import React, { useState, useEffect } from "react";
import styles from "./Brands.module.css";
import useAxios from "../../../hooks/useAxios";
import BrandsMenu from "../../sidebar/brandsmenu/BrandsMenu";
import Loader from "react-js-loader";
const BrandsList = () => {
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          brand_id: tasksObj[taskKey].brand_id,
          brand_img_uri: tasksObj[taskKey].brand_img_uri,
          title: tasksObj[taskKey].title,
          category_2_id: tasksObj[taskKey].category_2_id,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: "https://mason-api1.azurewebsites.net/api/brands",
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <div className={styles.find}>
      <BrandsMenu brands={tasks} />
    </div>
  );
};

export default BrandsList;
