import React from "react";
import Layout from "./components/layout/Layout";
import { AuthContextProvider } from "./store/AuthContext";
import { CartContextProvider } from "./store/CartContext";
import { WishlistProvider } from "./store/WishlistContext";
import { AddressContextProvider } from "./store/AddressContext";
import { ReviewsProvider } from "./store/ReviewContext";

function App() {
  return (
    <div>
      <AuthContextProvider>
        <CartContextProvider>
          <AddressContextProvider>
            <ReviewsProvider>
              <WishlistProvider>
                <Layout />
              </WishlistProvider>
            </ReviewsProvider>
          </AddressContextProvider>
        </CartContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
