import React from "react";
import { SUBSERVICES } from "../../../data/dummy-data";
import CustomlabourServices from "../../../components/services/CustomlabourServices";

function PayBills() {
  const displayedList = SUBSERVICES.filter((listItem) => {
    return listItem.catId == 1;
  });

  return (
    <div>
      <CustomlabourServices list={displayedList} />
    </div>
  );
}
export default PayBills;
