import React from "react";
import CardBackground from "../../components/ui/CardBackground";
import WishList from "../../images/wishlist.png";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const EmptyWishList = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={WishList}
          alt="Wishlist"
          style={{ width: "30%", height: "30%" }}
        />

        <div>
          <h4>WhistList is Empty</h4>
          <p> Save Your Favorite Products in one place.</p>
          <p>Add now , buy later</p>
        </div>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/")}>
            Explore Our Products
          </Button>
        </div>
      </CardBackground>
    </div>
  );
};

export default EmptyWishList;
