// MultiStepForm.js

import React, { useState } from "react";
import "./MultiStepForm.css";
import DeliveryOptions from "./DeliveryOptions";
import DeliverySlots from "./DeliverySlots";
import Order from "./Order";
import { useNavigate } from "react-router-dom";
import { useCartList } from "../../store/CartContext";
import { useAuthContext } from "../../store/AuthContext";

//import { useHistory } from "react-router-dom";
import axios from "axios";
const steps = ["Delivery Address", "Shipping Information", "Order Details"];

const AllSteps = () => {
  const [step, setStep] = useState(1);
  const { cart, deleteAllCartItem, setCart } = useCartList();
  const { userId, token } = useAuthContext();
  const [selectedAddress, setSelectedAdress] = useState("");
  const [responseState, setResponseState] = useState([]);
  const [paymentId, setPaymentId] = useState("");
  //const history = useHistory();
  const navigate = useNavigate();

  const total = cart
    .map((item) => Number(item.product_price * item.quantity))
    .reduce((prev, curr) => prev + curr, 0);
  //console.log(total, "total price");
  const shipping = 100;
  const discount = ((total * 5) / 100).toFixed(2);
  const tax = 100;
  const getFinalPrice = total + shipping + tax - discount;
  const orderType = 1;

  function selectedAddressHandler(item) {
    setSelectedAdress(item);
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const handleRazorpayPayment = async (paymentId) => {
    try {
      const response = await axios.get(
        `https://mason-api1.azurewebsites.net/api/order/payment/${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const paymentData = response.data;
      console.log(response);
      console.log(paymentData);
      setResponseState(paymentData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const handlePlaceOrder = async (e) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }
    const response = await fetch(
      "https://mason-api1.azurewebsites.net/api/order/createOrder",
      {
        method: "POST",
        body: JSON.stringify({
          amount: getFinalPrice * 100,
          currency: "INR",
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const order = await response.json();

    const razor_price = Number(getFinalPrice * 100);
    const ord = order.order_id;
    console.log(ord);
    var options = {
      key: "rzp_test_S0Pj3Siyr2ShlO", // Enter the Key ID generated from the Dashboard
      amount: razor_price, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Mason", //your business name
      order_id: ord,
      description: "Test Transaction",
      image: "https://example.com/your_logo",

      handler: async function (response) {
        console.log("Payment Success:", response);
        alert(response.razorpay_payment_id);
        setPaymentId(response.razorpay_payment_id);
        handleRazorpayPayment(response.razorpay_payment_id);

        const validateRes = await fetch(
          "https://mason-api1.azurewebsites.net/api/order/validate",
          {
            method: "POST",
            body: JSON.stringify(response),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const jsonRes = await validateRes.json();
        console.log(jsonRes);
        if (jsonRes.msg === "success") {
          alert(
            "Payment Successful",
            `Payment ID: ${response.razorpay_payment_id}`
          );

          navigate("/thankyou", { state: response.order_id });
          deleteAllCartItem();
          setCart([]);
        } else {
          alert("Payment Failed", jsonRes.message || "Validation failed.");
        }
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: "Mason Customer", //your customer's name
        email: "mason@example.com",
        contact: "9000000000", //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#37812C",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  const nextStep = () => {
    setStep((prevStep) => Math.min(prevStep + 1, steps.length));
  };

  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  return (
    <div className="multi-step-form">
      <div className="progress-bar">
        {steps.map((s, index) => (
          <div
            key={index}
            className={`step ${
              index < step ? "completed" : index === step - 1 ? "active" : ""
            }`}
          >
            {s}
          </div>
        ))}
      </div>

      {step === 1 && (
        <DeliveryOptions
          nextStep={nextStep}
          addressData={selectedAddressHandler}
        />
      )}
      {step === 2 && (
        <DeliverySlots
          nextStep={nextStep}
          prevStep={prevStep}
          selectedAddress={selectedAddress}
        />
      )}
      {step === 3 && (
        <Order
          prevStep={prevStep}
          handlePlaceOrder={handlePlaceOrder}
          total={total}
          selectedAddress={selectedAddress}
        />
      )}
    </div>
  );
};

export default AllSteps;
