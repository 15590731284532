import React, { useState, useEffect } from "react";
import styles from "./YourOrders.module.css";

import { useParams } from "react-router-dom";
import { useAuthContext } from "../../store/AuthContext";
import YourOrderItemsData from "./YourOrderItemsData";
import useAxios from "../../hooks/useAxios";
import Loader from "react-js-loader";
import CustomTitle from "../../components/ui/CustomTitle";

function YourOrderItems() {
  const params = useParams();
  const orderId = params.id;

  const { token } = useAuthContext();
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          orderItemId: tasksObj[taskKey].order_item_id,
          orderId: tasksObj[taskKey].order_id,
          product_id: tasksObj[taskKey].product_id,
          quantity: tasksObj[taskKey].quantity,
          product_name: tasksObj[taskKey].product_name,
          product_brand: tasksObj[taskKey].product_brand,
          product_type: tasksObj[taskKey].product_type,
          product_quality: tasksObj[taskKey].product_quality,
          price_unit: tasksObj[taskKey].price_unit,
          product_price: tasksObj[taskKey].product_price,
          product_img_uri: tasksObj[taskKey].product_img_uri,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: `https://mason-api1.azurewebsites.net/api/order-items/${orderId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.luxury}>
      <CustomTitle>Your Order Items</CustomTitle>
      <div>
        {tasks.map((item, index) => (
          <div key={index}>
            <YourOrderItemsData item={item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(YourOrderItems);
