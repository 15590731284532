import React from 'react'

import SubCategories from '../../components/home/categories/SubCategories';

function SubCategoriesPage() {
  return (
    <div>
      <SubCategories />
    </div>

  );
}

export default SubCategoriesPage;
