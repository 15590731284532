import React from "react";
import styles from "./Banners.module.css";

function Banners() {
  return (
    <div className={styles.carousel}>
      <img
        src="https://www.animexhomes.com/building-material/img/animex-construction-material-banner.jpg"
        alt="Banner"
        style={{ borderRadius: "20px", width: "100%", height: "220px" }}
      />
    </div>
  );
}

export default Banners;
