const Colors = {
  primary500: "#121214",
  primary600: "#F6C540",
  primary700: "#ffffff",
  primary800: "#000000",
  primary900: "#524F4F",
  primary100: "#95ED9F",
  primary200: "#BEBEBE",
  primary300: "#CCCCCC",
  primary400: "#D72340",
  primary1000: "#F6BFCD",
  primary2000: "#FD4F46",
  primary3000: "#EEEEEE",
};

export default Colors;
