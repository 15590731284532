import React from "react";

function Water() {
  return (
    <div>
      <h2>Water Bill</h2>
    </div>
  );
}

export default Water;
