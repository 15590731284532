import React, { useState } from "react";
import styles from "./DeliveryOptions.module.css";
import CardBackground from "../../components/ui/CardBackground";
import Button from "../../components/ui/Button";

import { useCartList } from "../../store/CartContext";
import CustomTitle from "../../components/ui/CustomTitle";

const Order = ({ prevStep, handlePlaceOrder, selectedAddress, total }) => {
  const { cart } = useCartList();
  const [deliveryAddress, setDeliveryAddress] = useState(selectedAddress);

  return (
    <div className={styles.luxury}>
      <CustomTitle>Confirm Your Order</CustomTitle>
      <div className={styles.container}>
        <div style={{ marginHorizontal: 20 }}>
          <CardBackground>
            <div>
              <p>
                <b> Delivering to {deliveryAddress.first_name}</b>
              </p>
              <p>
                {deliveryAddress.address},{deliveryAddress.city},
                {deliveryAddress.state}
                <br />
                {deliveryAddress.country},{deliveryAddress.pincode},
                {deliveryAddress.mobile}
              </p>
            </div>
          </CardBackground>

          <CardBackground>
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <p style={{ fontSize: 16, fontWeight: "500", color: "gray" }}>
                Items
              </p>

              <p style={{ color: "gray", fontSize: 16 }}>₹{total}</p>
            </div>

            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <p style={{ fontSize: 16, fontWeight: "500", color: "gray" }}>
                Delivery
              </p>

              <p style={{ color: "gray", fontSize: 16 }}>₹0</p>
            </div>

            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <p style={{ fontSize: 20, fontWeight: "bold" }}>Order Total</p>

              <p style={{ color: "#C60C30", fontSize: 17, fontWeight: "bold" }}>
                ₹{total}
              </p>
            </div>
          </CardBackground>
          {cart.map((list) => (
            <CardBackground key={list.product_id}>
              <div className={styles.cardorder}>
                <img src={list.product_img_uri} alt={list.product_name} />
                <div className={styles.content}>
                  <h3>{list.product_name}</h3>
                  <p>
                    <b>Brand: </b>
                    {list.product_brand}
                  </p>
                  <p>
                    <b>Type: </b>
                    {list.product_quality}
                  </p>
                  <p>
                    <b>Price:</b> ₹{list.product_price}
                  </p>
                  <p>
                    <b>Quantity:</b> ₹{list.quantity}
                  </p>
                </div>
              </div>
            </CardBackground>
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <Button type="submit" onClick={prevStep}>
                Previous
              </Button>
            </div>
            <div>
              <Button type="submit" onClick={handlePlaceOrder}>
                Confirm Your Order
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
