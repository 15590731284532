import React from "react";

function Google() {
  return (
    <div>
      <h2>Google</h2>
    </div>
  );
}

export default Google;
