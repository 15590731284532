import React from "react";

import CustomlabourServices from "../../../components/services/CustomlabourServices";
import { OTHERSUBSERVICES } from "../../../data/dummy-data";

function Painting() {
  const displayedList = OTHERSUBSERVICES.filter((listItem) => {
    return listItem.catId == 18;
  });

  return (
    <div>
      <CustomlabourServices list={displayedList} />
    </div>
  );
}

export default Painting;
