import React from "react";
import { useAddressList } from "../../store/AddressContext";
import EmptyAdresses from "./EmptyAdresses";
import AddressbookData from "./AddressbookData";

function Addressbook() {
  const { address } = useAddressList();

  return (
    <div>
      {address.length === 0 && <EmptyAdresses />}
      {address.length !== 0 && <AddressbookData />}
    </div>
  );
}

export default Addressbook;
