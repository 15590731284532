// In your WishlistContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";
import axios from "axios";

const API_URL = "https://mason-api1.azurewebsites.net/api"; // replace with your backend URL

const WishlistContext = createContext();

export const useWishlist = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }) => {
  const { token, userId } = useAuthContext();

  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await axios.get(`${API_URL}/wishlist/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const wishlistItems = response.data;
        setWishlist(wishlistItems);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    if (userId) {
      fetchWishlist();
    }
  }, [userId, token]);
  //console.log(wishlist);

  const addWishlistProduct = async (productId) => {
    //console.log(productId, token, userId);
    try {
      const response = await axios.post(
        `${API_URL}/wishlist`,
        { user_id: userId, product_id: productId }, // Include userId and productId in the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newItem = response.data;
      //console.log(newItem);
      setWishlist([...wishlist, newItem]);
      //console.log(wishlist);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const removeWishlistProduct = async (productId) => {
    // console.log(productId);

    try {
      const response = await axios.delete(`${API_URL}/wishlist`, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is set
        },
        data: {
          userId, // Assuming you're sending userId and productId in the request body
          productId,
        },
      });

      setWishlist(wishlist.filter((item) => item.product_id !== productId));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <WishlistContext.Provider
      value={{ wishlist, addWishlistProduct, removeWishlistProduct }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
