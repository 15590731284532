import React from "react";

import ProductsOverViewData from "../../components/home/categories/ProductsOverViewData";
import { useLocation } from "react-router-dom";

function ProductsOverViewPage() {
  const location = useLocation();
  const data = location.state;
  // console.log(data);
  return (
    <div>
      <ProductsOverViewData list={data} />
    </div>
  );
}

export default ProductsOverViewPage;
