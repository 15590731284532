import React from "react";
import { SUBSERVICES } from "../../../data/dummy-data";
import CustomlabourServices from "../../../components/services/CustomlabourServices";

function Recharge() {
  const displayedList = SUBSERVICES.filter((listItem) => {
    return listItem.catId == 4;
  });

  return (
    <div>
      <CustomlabourServices list={displayedList} />
    </div>
  );
}

export default Recharge;
