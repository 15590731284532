import React from "react";
import styles from "./DeliveryOptions.module.css";

import Button from "../../components/ui/Button";
import { useNavigate, useLocation } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const orderId = location.state;
  console.log(location);
  console.log(orderId);
  return (
    <div className={styles.luxury}>
      <div className={styles.heading}>
        <h3> Thank You For Shopping With Us!</h3>
      </div>
      <div className={styles.content}>
        <h3> Your Order Number is {orderId}.</h3>
      </div>
      <div className={styles.container}>
        <div style={{ width: "20%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/")}>
            Shop Again
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
