import React, { useState, useEffect } from "react";
import styles from "./Brands.module.css";
import Card from "./Card";
import useAxios from "../../../hooks/useAxios";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import Loader from "react-js-loader";
import CustomTitle from "../../ui/CustomTitle";

const Brands = () => {
  const [tasks, setTasks] = useState([]);
  const { isLoading, error, sendRequest: fetchTasks } = useAxios();

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      for (const taskKey in tasksObj) {
        loadedTasks.push({
          brand_id: tasksObj[taskKey].brand_id,
          brand_img_uri: tasksObj[taskKey].brand_img_uri,
          title: tasksObj[taskKey].title,
        });
      }

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: "https://mason-api1.azurewebsites.net/api/brands",
      },
      transformTasks
    );
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader type="bubble-scale" bgColor="#F6C540" size={50} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <div className={styles.find}>
      <CustomTitle>Explore Top Brands</CustomTitle>
      <div className={styles.slider_container}>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={6}
          navigation
          breakpoints={{
            // when window width is >= 340px
            340: {
              width: 200,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 4,
            },
            // when window width is >= 1040px
            1040: {
              width: 1040,
              slidesPerView: 5,
            },
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {tasks.map((item) => (
            <SwiperSlide key={item.brand_id}>
              <Card
                image={item.brand_img_uri}
                make={item.title}
                id={item.brand_id}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Brands;
