import React from "react";
import CardBackground from "../../components/ui/CardBackground";
import NoOrders from "../../images/noOrders.png";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const EmptyOrders = () => {
  const navigate = useNavigate();
  return (
    <div className="luxury">
      <CardBackground>
        <img
          src={NoOrders}
          alt="No Orders"
          style={{ width: "30%", height: "30%" }}
        />
        <div>
          <h4>You have not placed an order yet.</h4>
          <p>Please add items to your cart and checkout when you are ready</p>
        </div>
        <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
          <Button type="submit" onClick={() => navigate("/")}>
            Explore Our Products
          </Button>
        </div>
      </CardBackground>
    </div>
  );
};

export default EmptyOrders;
