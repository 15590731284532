import React from "react";
import styles from "./CartData.module.css";
import { useNavigate } from "react-router-dom";
import WhiteButton from "../ui/WhiteButton";
import CardBackground from "../ui/CardBackground";
import { useCartList } from "../../store/CartContext";
import { useWishlist } from "../../store/WishlistContext";
import CustomTitle from "../ui/CustomTitle";

const SaveForLater = ({ list }) => {
  const navigate = useNavigate();
  const { removeWishlistProduct } = useWishlist();
  const { addToCartItem } = useCartList();

  function selectListItemHandler(item) {
    navigate(`/productsOverview/${item.product_id}`, { state: item });
  }
  const toggleWishlist = (item) => {
    //console.log(item);
    removeWishlistProduct(item);
  };
  function AddItemHandler(list) {
    //console.log(list);
    const cartNumber = 1;
    addToCartItem({ list, cartNumber });
    removeWishlistProduct(list.product_id);
  }

  return (
    <div className="luxury">
      <CustomTitle>Save for Later</CustomTitle>
      <CardBackground>
        {list.map((item, index) => (
          <div className={styles.card} key={index}>
            <img
              src={item.product_img_uri}
              alt={item.product_name}
              onClick={() => selectListItemHandler(item)}
            />

            <div className="content">
              <h3>{item.product_name}</h3>
              <p>
                <b>Brand: </b>
                {item.product_brand}
              </p>
              <p>
                <b>Type: </b>
                {item.product_type}
              </p>
              <p>
                <b>Price:</b> ₹{item.product_price}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <WhiteButton
                    type="submit"
                    onClick={() => toggleWishlist(item.product_id)}
                  >
                    Delete
                  </WhiteButton>
                </div>
                <div>
                  <WhiteButton
                    type="submit"
                    onClick={() => AddItemHandler(item)}
                  >
                    Move to Cart
                  </WhiteButton>
                </div>
              </div>
            </div>
          </div>
        ))}
      </CardBackground>
    </div>
  );
};

export default SaveForLater;
