import React, { useState } from "react";
import styles from "./YourOrders.module.css";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

import { Link } from "react-router-dom";
import CustomTitle from "../../components/ui/CustomTitle";

function YourOrdersData({ data }) {
  const [selected, setSelected] = useState(null);

  const toggle = (list) => {
    if (selected === list) {
      return setSelected(null);
    }
    setSelected(list);
  };

  return (
    <div className={styles.luxury}>
      <CustomTitle>Your Orders</CustomTitle>
      {data.map((list, index) => (
        <div key={list.orderId} className={styles.card}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid  #eee",
              justifyContent: "space-between",
            }}
          >
            <div className={styles.innercontent}>
              <div style={{ marginRight: "20px" }}>
                <p>
                  <b>Order Placed </b>
                </p>
                <p>16 December 2023</p>
              </div>

              <div style={{ marginRight: "20px" }}>
                <p>
                  <b>Price </b>
                </p>
                <p>{list.sub_total}</p>
              </div>
              <div style={{ marginRight: "20px" }}>
                <p>
                  <b>Shipped to </b>
                </p>
                <p> customer1</p>
              </div>
            </div>
            <div className={styles.innercontent}>
              <div style={{ marginRight: "20px" }}>
                <p>
                  <b>Order Id : {list.orderId}</b>
                </p>
              </div>

              <div style={{ marginRight: "20px" }}>
                <Link
                  className={styles.link}
                  onClick={() => toggle(list.orderId)}
                >
                  View Order Details
                </Link>
                {selected === list.orderId ? (
                  <AiOutlineUp size={12} color="#121214" />
                ) : (
                  <AiOutlineDown size={12} color="#121214" />
                )}
              </div>
              <div style={{ marginRight: "20px" }}>
                <Link
                  className={styles.link}
                  to={`/profile/YourOrderItems/${list.orderId} `}
                >
                  View Order Items <AiOutlineDown size={12} color="#121214" />
                </Link>
              </div>
            </div>
          </div>

          {selected === list.orderId && (
            <div className={styles.innercontent}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",

                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <p>
                    <b>Shipping Address</b>
                  </p>
                  <p>Prasad</p>
                  <p>201,Sai Lakshmi residency,</p>
                  <p>Indiranagar,Gandhi Statue lane, chandanagar</p>
                  <p>HYDERABAD, TELANGANA 500050</p>
                  <p>India </p>
                </div>

                <div style={{ marginRight: "20px" }}>
                  <p>
                    <b>Payment Method </b>
                  </p>
                  <p> Payment Methods</p>
                  <p>Amazon Pay ICICI Bank Credit Credit Cardending in 3003</p>
                </div>
                <div style={{ marginRight: "20px" }}>
                  <p>
                    <b>Order Summary </b>
                  </p>
                  <p>Item(s) Subtotal:</p>
                  <p> 1,343.00</p>
                  <p>Shipping: 0.00</p>
                  <p>Total: 1,343.00</p>
                  <p>Promotion Applied:- 40.29</p>
                  <p>Grand Total: 1,302.71</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default YourOrdersData;
