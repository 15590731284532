import React from "react";

function AppStore() {
  return (
    <div>
      <h2>AppStore</h2>
    </div>
  );
}

export default AppStore;
