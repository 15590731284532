import useInput from "../../hooks/use-input";
import loginImg from "../../images/login.png";
import CardBackground from "../ui/CardBackground";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../store/AuthContext";

const isNotEmpty = (value) => value.trim() !== "";

const Login = (props) => {
  const navigate = useNavigate();
  const { login } = useAuthContext();

  const {
    value: userNameValue,
    isValid: userNameIsValid,
    hasError: userNameHasError,
    valueChangeHandler: userNameChangeHandler,
    inputBlurHandler: userNameBlurHandler,
    reset: resetUserName,
  } = useInput(isNotEmpty);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (userNameIsValid && passwordIsValid) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    const credentials = {
      username: userNameValue,
      password: passwordValue,
    };
    login(credentials);

    resetUserName();
    resetPassword();
    navigate("/");
  };

  function RegisterPageHandler() {
    navigate("/register");
  }
  function ForgotPasswordHandler() {
    //navigate('/forgotPassword');
  }

  const userNameClasses = userNameHasError
    ? "form-control invalid"
    : "form-control";
  const passwordClasses = passwordHasError
    ? "form-control invalid"
    : "form-control";

  return (
    <div className="luxury" style={{ maxWidth: "800px" }}>
      <CardBackground>
        <img src={loginImg} alt="login" />
        <form onSubmit={submitHandler}>
          <div className="control-group">
            <div className={userNameClasses}>
              <input
                type="text"
                placeholder="User Name"
                value={userNameValue}
                onChange={userNameChangeHandler}
                onBlur={userNameBlurHandler}
              />
              {userNameHasError && (
                <p className="error-text">Please enter your user name.</p>
              )}
            </div>
            <div className={passwordClasses}>
              <input
                type="password"
                placeholder="Password"
                value={passwordValue}
                onChange={passwordChangeHandler}
                onBlur={passwordBlurHandler}
              />
              {passwordHasError && (
                <p className="error-text">Please enter a Password.</p>
              )}
            </div>
          </div>
          <div onClick={RegisterPageHandler} style={{ cursor: "pointer" }}>
            <p>Don't have an account? Sign Up </p>
          </div>

          <div style={{ width: "30%", padding: "auto", margin: "auto" }}>
            <Button type="submit" disabled={!formIsValid}>
              Login
            </Button>
          </div>
          <div onClick={ForgotPasswordHandler} style={{ cursor: "pointer" }}>
            <p>Forgot Your Password ? </p>
          </div>
        </form>
      </CardBackground>
    </div>
  );
};

export default Login;
