import React from "react";

function MetroRecharge() {
  return (
    <div>
      <h2>Metro Recharge</h2>
    </div>
  );
}

export default MetroRecharge;
