import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./header/Header";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import HomePage from "../../pages/home/HomePage";
import CategoriesPage from "../../pages/home/CategoriesPage";
import SubCategoriesPage from "../../pages/home/SubCategoriesPage";
import ProductsPage from "../../pages/home/ProductsPage";
import ProductsdataPage from "../../pages/home/ProductsdataPage";
import ProductsOverViewPage from "../../pages/home/ProductsOverViewPage";
import LoginPage from "../../pages/login/LoginPage";
import RegistrationPage from "../../pages/login/RegistrationPage";
import MyCart from "../../pages/cart/MyCart";
import Deals from "../../pages/home/Deals";
import Profile from "../../pages/profile/Profile";
import AddtoCartScreen from "../../pages/cart/AddtoCartScreen";
import DeliveryOptions from "../../pages/cart/DeliveryOptions";
import DeliverySlots from "../../pages/cart/DeliverySlots";
import Payment from "../../pages/cart/Payment";
import Order from "../../pages/cart/Order";
import ThankYou from "../../pages/cart/ThankYou";
import AllSteps from "../../pages/cart/AllSteps";
import NewAddress from "../../pages/cart/NewAddress";
import YourOrders from "../../pages/profile/YourOrders";
import YourAccount from "../../pages/profile/YourAccount";
import WishList from "../../pages/profile/WishList";
import BuyAgain from "../../pages/profile/BuyAgain";
import Mcash from "../../pages/profile/Mcash";
import Msmile from "../../pages/profile/Msmile";
import Savings from "../../pages/profile/Savings";
//import BreadCrumb from "../ui/BreadCrumb";
import Addressbook from "../../pages/profile/Addressbook";

import ManagePayments from "../../pages/profile/ManagePayments";
import ServicesHomePage from "../../pages/services/ServicesHomePage";
import ElectricBill from "../../pages/services/payBills/ElectricBill";
import BroadBand from "../../pages/services/payBills/BroadBand";
import CreditCard from "../../pages/services/payBills/CreditCard";
import EducationFees from "../../pages/services/payBills/EducationFees";
import GasBill from "../../pages/services/payBills/GasBill";
import Hospital from "../../pages/services/payBills/Hospital";
import Insurance from "../../pages/services/payBills/Insurance";
import Landline from "../../pages/services/payBills/Landline";
import LoanRepayment from "../../pages/services/payBills/LoanRepayment";
import MobilePost from "../../pages/services/payBills/MobilePost";
import MuncipalServices from "../../pages/services/payBills/MuncipalServices";
import Municipality from "../../pages/services/payBills/Municipality";
import PayRent from "../../pages/services/payBills/PayRent";
import PippedGas from "../../pages/services/payBills/PippedGas";
import Water from "../../pages/services/payBills/Water";
import AcGas from "../../pages/services/AC/AcGas";
import AcInstallation from "../../pages/services/AC/AcInstallation";
import AcRepair from "../../pages/services/AC/AcRepair";
import AcService from "../../pages/services/AC/AcService";
import BathroomCleaning from "../../pages/services/cleaning/BathroomCleaning";
import WeeklyCleaning from "../../pages/services/cleaning/WeeklyCleaning";
import SofaCleaning from "../../pages/services/cleaning/SofaCleaning";
import PremiumCleaning from "../../pages/services/cleaning/PremiumCleaning";
import KitchenCleaning from "../../pages/services/cleaning/KitchenCleaning";
import HomeCleaning from "../../pages/services/cleaning/HomeCleaning";
import Carpentry from "../../pages/services/labourServices/Carpentry";
import Electrician from "../../pages/services/labourServices/Electrician";
import Painting from "../../pages/services/labourServices/Painting";
import Plumbing from "../../pages/services/labourServices/Plumbing";
import AppStore from "../../pages/services/recharge/AppStore";
import Cable from "../../pages/services/recharge/Cable";
import DTH from "../../pages/services/recharge/DTH";
import Fastag from "../../pages/services/recharge/Fastag";
import Google from "../../pages/services/recharge/Google";
import MetroRecharge from "../../pages/services/recharge/MetroRecharge";
import Mobile from "../../pages/services/recharge/Mobile";
import Subscriptions from "../../pages/services/recharge/Subscriptions";
import PayBills from "../../pages/services/AllPages/PayBills";
import ACService from "../../pages/services/AllPages/ACService";
import Cleaning from "../../pages/services/AllPages/Cleaning";
import LabourServices from "../../pages/services/AllPages/LabourServices";
import Recharge from "../../pages/services/AllPages/Recharge";
import EmptyOrders from "../../pages/profile/EmptyOrders";
import EmptyAdresses from "../../pages/profile/EmptyAdresses";
import EmptyBuyAgain from "../../pages/profile/EmptyBuyAgain";
import EmptyPayments from "../../pages/profile/EmptyPayments";
import EmptyWishList from "../../pages/profile/EmptyWishList";
import Emptypage from "./Emptypage";
import { useAuthContext } from "../../store/AuthContext";
import YourOrderItems from "../../pages/profile/YourOrderItems";
import BrandsList from "../home/brands/BrandsList";
import BrandsOverView from "../home/brands/BrandsOverView";
import BrandsListPage from "../../pages/home/BrandsListPage";
import PrivacyPolicy from "../../pages/home/PrivacyPolicy";

function Layout() {
  const { isAuthenticated } = useAuthContext();
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Navbar />
        {/*   <BreadCrumb /> */}
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/category/:id" element={<CategoriesPage />} />
            <Route path="/subCategories/:id" element={<SubCategoriesPage />} />
            <Route path="/productsdata/:id" element={<ProductsdataPage />} />
            <Route path="/products/:id" element={<ProductsPage />} />
            <Route path="/services" element={<ServicesHomePage />} />
            <Route
              path="/productsOverview/:id"
              element={<ProductsOverViewPage />}
            />
            <Route path="/brands" element={<BrandsList />} />
            <Route path="/brandsList/:id" element={<BrandsListPage />} />
            <Route path="/brandsOverview/:id" element={<BrandsOverView />} />
            <Route path="/auth" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />

            {isAuthenticated && (
              <Route path="/updateRegister" element={<RegistrationPage />} />
            )}
            <Route path="/myCart" element={<MyCart />} />
            <Route path="/addtoCart" element={<AddtoCartScreen />} />
            <Route path="/deals" element={<Deals />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            {isAuthenticated && (
              <Route path="/allSteps" element={<AllSteps />} />
            )}
            {isAuthenticated && (
              <Route path="/deliveryOptions" element={<DeliveryOptions />} />
            )}
            {isAuthenticated && (
              <Route path="/newAddress" element={<NewAddress />} />
            )}
            {isAuthenticated && (
              <Route path="/deliverySlots" element={<DeliverySlots />} />
            )}
            {isAuthenticated && <Route path="/payment" element={<Payment />} />}
            {isAuthenticated && <Route path="/order" element={<Order />} />}
            {isAuthenticated && (
              <Route path="/thankyou" element={<ThankYou />} />
            )}
            {/*  <Route path="/thankyou" element={<ThankYou />} />*/}
            {isAuthenticated && <Route path="/profile" element={<Profile />} />}

            {isAuthenticated && (
              <Route path="/profile/YourOrders" element={<YourOrders />} />
            )}
            {isAuthenticated && (
              <Route
                path="/profile/YourOrderItems/:id"
                element={<YourOrderItems />}
              />
            )}
            {isAuthenticated && (
              <Route path="/profile/YourAccount" element={<YourAccount />} />
            )}
            {isAuthenticated && (
              <Route path="/profile/BuyAgain" element={<BuyAgain />} />
            )}
            {isAuthenticated && (
              <Route path="/profile/YourWishList" element={<WishList />} />
            )}
            {isAuthenticated && (
              <Route path="/profile/AddressBook" element={<Addressbook />} />
            )}
            {isAuthenticated && (
              <Route path="/profile/mcash" element={<Mcash />} />
            )}
            {isAuthenticated && (
              <Route path="/profile/msmiles" element={<Msmile />} />
            )}
            {isAuthenticated && (
              <Route path="/profile/savings" element={<Savings />} />
            )}
            {isAuthenticated && (
              <Route
                path="/profile/managePayments"
                element={<ManagePayments />}
              />
            )}
            {isAuthenticated && (
              <Route path="/profile/EmptyOrders" element={<EmptyOrders />} />
            )}
            {isAuthenticated && (
              <Route
                path="/profile/EmptyAdresses"
                element={<EmptyAdresses />}
              />
            )}
            {isAuthenticated && (
              <Route
                path="/profile/EmptyBuyAgain"
                element={<EmptyBuyAgain />}
              />
            )}
            {isAuthenticated && (
              <Route
                path="/profile/EmptyPayments"
                element={<EmptyPayments />}
              />
            )}
            {isAuthenticated && (
              <Route
                path="/profile/EmptyWishList"
                element={<EmptyWishList />}
              />
            )}

            <Route path="/services/PayBills" element={<PayBills />} />
            <Route path="/services/ElectricBill" element={<ElectricBill />} />
            <Route path="/services/BroadBand" element={<BroadBand />} />
            <Route path="/services/CreditCard" element={<CreditCard />} />
            <Route path="/services/EducationFees" element={<EducationFees />} />
            <Route path="/services/GasBill" element={<GasBill />} />
            <Route path="/services/Hospital" element={<Hospital />} />
            <Route path="/services/Insurance" element={<Insurance />} />
            <Route path="/services/Landline" element={<Landline />} />
            <Route path="/services/LoanRepayment" element={<LoanRepayment />} />
            <Route path="/services/MobilePost" element={<MobilePost />} />
            <Route
              path="/services/MuncipalServices"
              element={<MuncipalServices />}
            />
            <Route path="/services/Municipality" element={<Municipality />} />
            <Route path="/services/PayRent" element={<PayRent />} />
            <Route path="/services/PippedGas" element={<PippedGas />} />
            <Route path="/services/Water" element={<Water />} />

            <Route path="/services/ACService" element={<ACService />} />
            <Route path="/services/AcGas" element={<AcGas />} />
            <Route
              path="/services/AcInstallation"
              element={<AcInstallation />}
            />
            <Route path="/services/AcRepair" element={<AcRepair />} />
            <Route path="/services/AcService" element={<AcService />} />
            <Route path="/services/Cleaning" element={<Cleaning />} />
            <Route
              path="/services/BathroomCleaning"
              element={<BathroomCleaning />}
            />
            <Route path="/services/HomeCleaning" element={<HomeCleaning />} />
            <Route
              path="/services/KitchenCleaning"
              element={<KitchenCleaning />}
            />
            <Route
              path="/services/PremiumCleaning"
              element={<PremiumCleaning />}
            />
            <Route path="/services/SofaCleaning" element={<SofaCleaning />} />
            <Route
              path="/services/WeeklyCleaning"
              element={<WeeklyCleaning />}
            />
            <Route
              path="/services/LabourServices"
              element={<LabourServices />}
            />
            <Route path="/services/Carpentry" element={<Carpentry />} />
            <Route path="/services/Electrician" element={<Electrician />} />
            <Route path="/services/Painting" element={<Painting />} />
            <Route path="/services/Plumbing" element={<Plumbing />} />
            <Route path="/services/Recharge" element={<Recharge />} />
            <Route path="/services/AppStore" element={<AppStore />} />
            <Route path="/services/Cable" element={<Cable />} />
            <Route path="/services/DTH" element={<DTH />} />
            <Route path="/services/Fastag" element={<Fastag />} />
            <Route path="/services/Google" element={<Google />} />
            <Route path="/services/MetroRecharge" element={<MetroRecharge />} />
            <Route path="/services/Mobile" element={<Mobile />} />
            <Route path="/services/Subscriptions" element={<Subscriptions />} />
            <Route path="*" element={<Emptypage />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Layout;
