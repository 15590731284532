import React from "react";

import Categories from "../../components/home/categories/Categories";

function CategoriesPage() {
  return (
    <div>
      <Categories />
    </div>
  );
}

export default CategoriesPage;
