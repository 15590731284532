import styles from "./Header.module.css";
import { AiOutlineUser, AiOutlineShoppingCart } from "react-icons/ai";
//import { FaTruck } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { TbLogout } from "react-icons/tb";

import { useCartList } from "../../../store/CartContext";
import { useAuthContext } from "../../../store/AuthContext";
import Colors from "../../ui/Colors";

function HeaderIcons() {
  const { cart } = useCartList();
  const { isAuthenticated, user, logout } = useAuthContext();
  const navigate = useNavigate();

  const total = cart
    .map((item) => Number(item.product_price * item.quantity))
    .reduce((prev, curr) => prev + curr, 0);

  const LogoutHandler = async () => {
    await logout();
    navigate(`/`);
  };
  return (
    <div>
      <ul className={styles.menu}>
        <li>
          <Link to={isAuthenticated ? `/profile` : `/auth`}>
            <AiOutlineUser
              size={30}
              color={Colors.primary600}
              style={{ marginTop: "10px" }}
            />
            <h3>{isAuthenticated ? user : "Sign In"}</h3>
          </Link>
        </li>
        <li>
          {isAuthenticated ? (
            <Link to={"/myCart"}>
              <AiOutlineShoppingCart
                size={30}
                color={Colors.primary600}
                style={{ marginTop: "20px" }}
              />

              <div className={styles.cartNumber}>{cart.length}</div>
              {total === 0 ? null : (
                <div>
                  <p>₹{total}</p>
                </div>
              )}
            </Link>
          ) : (
            <Link to={"/myCart"}>
              <AiOutlineShoppingCart
                size={30}
                color={Colors.primary600}
                style={{ marginTop: "20px" }}
              />
            </Link>
          )}
        </li>
        <li>
          {isAuthenticated && (
            <Link onClick={LogoutHandler}>
              <TbLogout
                size={30}
                color={Colors.primary600}
                style={{ marginTop: "20px" }}
              />
            </Link>
          )}
        </li>

        {/* <li>
          <FaTruck size={30} color='#121214' />
        </li> */}
      </ul>
    </div>
  );
}

export default HeaderIcons;
