import React from "react";
import Carousel from "../../components/home/slider/Carousel";

import Categories from "../../components/home/categories/Categories";
import Banners from "../../components/home/banners/Banners";
import Brands from "../../components/home/brands/Brands";
import HomePageServices from "../../components/services/HomePageServices";
//import Payment from "./Payment";

function HomePage() {
  return (
    <div>
      <div>
        <p style={{ color: "#D92341", textAlign: "center", marginTop: "20px" }}>
          <b>
            The Application is currently being developed and not yet finished or
            ready for public use
          </b>
        </p>
      </div>
      <Carousel />
      <Categories />
      <HomePageServices />
      <Banners />
      <Brands />
      {/*  <Payment /> */}
    </div>
  );
}

export default HomePage;
