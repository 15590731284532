import React, { useState, useEffect } from "react";
import Button from "../../components/ui/Button";
import styles from "./DeliveryOptions.module.css";
import CardBackground from "../../components/ui/CardBackground";
import WhiteButton from "../../components/ui/WhiteButton";
import { useAddressList } from "../../store/AddressContext";
import { useNavigate, Link } from "react-router-dom";
import { useAuthContext } from "../../store/AuthContext";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import CustomTitle from "../../components/ui/CustomTitle";

const DeliveryOptions = ({ nextStep, addressData }) => {
  const { address, deleteAddress } = useAddressList();

  const navigate = useNavigate();
  const [addressSelected, setAddressSelected] = useState("");

  const DeliveryFormHandler = (item) => {
    navigate("/newAddress", { state: { data: item } });
  };

  const DeleteFormHandler = (address_id) => {
    deleteAddress(address_id);
  };
  function nextStepHandler() {
    if (addressSelected) {
      nextStep();
    } else {
      alert("please select one Address");
      //console.log("please select one Address");
    }
  }
  function AddressHandler(item) {
    setAddressSelected(item);
    addressData(item);
  }
  return (
    <div className={styles.luxury}>
      <CustomTitle>Select a delivery address</CustomTitle>
      {address?.map((item, index) => (
        <CardBackground key={index}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {addressSelected.address_id === item?.address_id ? (
              <IoIosRadioButtonOn size={20} color="#121214" />
            ) : (
              <IoIosRadioButtonOff
                onClick={() => AddressHandler(item)}
                size={20}
                color="gray"
              />
            )}
            <p>
              <b>
                {item.first_name} {item.last_name}
              </b>
              ,{item.address},{item.city},{item.state}
              <br />
              {item.country},{item.pincode},{item.mobile}
            </p>

            {addressSelected.address_id === item.address_id && (
              <div
                style={{
                  width: "50%",
                  padding: "auto",
                  margin: "auto",
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                }}
              >
                <WhiteButton
                  type="submit"
                  onClick={() => DeliveryFormHandler(item)}
                >
                  Edit Address
                </WhiteButton>
                <WhiteButton
                  type="submit"
                  onClick={() => DeleteFormHandler(item?.address_id)}
                >
                  Delete Address
                </WhiteButton>
              </div>
            )}
          </div>
        </CardBackground>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div style={{ marginRight: "10px" }}>
          <Button type="submit" onClick={() => navigate("/myCart")}>
            Previous
          </Button>
        </div>
        {address.length != 0 && (
          <div style={{ marginRight: "10px" }}>
            <Button type="submit" onClick={nextStepHandler}>
              Next
            </Button>
          </div>
        )}
        <div>
          <Button type="submit" onClick={() => navigate("/newAddress")}>
            Add New Address
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOptions;
