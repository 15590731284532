import styles from "./profile.module.css";
import { useNavigate } from "react-router-dom";

import YourOrders from "../../images/profile/YourOrders.png";
import YourAccount from "../../images/profile/YourAccount.png";
import BuyAgain from "../../images/profile/BuyAgain.png";
import YourWishList from "../../images/profile/YourWishList.png";
import AddressBook from "../../images/profile/AddressBook.png";
import PaymentOptions from "../../images/profile/PaymentOptions.png";
import { useAuthContext } from "../../store/AuthContext";
//import MCash from "../../images/profile/MCash.png";
//import MSmiles from "../../images/profile/MSmiles.png";
//import CashBack from "../../images/profile/CashBack.png";

const List = [
  {
    id: "0",
    image: YourOrders,
    title: "Your Orders",
    name: "YourOrders",
  },
  {
    id: "1",
    image: YourAccount,
    title: "Your Account",
    name: "YourAccount",
  },
  {
    id: "2",
    image: BuyAgain,
    title: "Buy Again",
    name: "BuyAgain",
  },
  {
    id: "3",
    image: YourWishList,
    title: "Your WishList",
    name: "YourWishList",
  },
  {
    id: "4",
    image: AddressBook,
    title: "Address Book ",
    name: "AddressBook",
  },
  {
    id: "5",
    image: PaymentOptions,
    title: "Payments",
    name: "managePayments",
  },
  /*  {
    id: "6",
    image: MCash,
    title: "MCash",
    name: "MCash",
  },
  {
    id: "7",
    image: MSmiles,
    title: "MSmiles ",
    name: "MSmiles",
  },
  {
    id: "8",
    image: CashBack,
    title: "Savings",
    name: "Savings",
  }, */
];

function Profile() {
  const navigate = useNavigate();

  const { user } = useAuthContext();

  return (
    <div className={styles.luxury}>
      <div className={styles.heading}>
        <h3> Hello {user}</h3>
      </div>

      <div className={styles.container}>
        {List.map((item) => (
          <div
            className={styles.card}
            key={item.id}
            onClick={() => navigate(item.name)}
          >
            <div className={styles.content}>
              <img src={item.image} alt={item.title} />
              <h3>{item.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Profile;
