import React from "react";

function CreditCard() {
  return (
    <div>
      <h2>CreditCard</h2>
    </div>
  );
}

export default CreditCard;
